export const buttonGroupStyle = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
};

export const buttonStyle = {
  flex: 1,
  minWidth: 250,
  maxWidth: 400,
  margin: "5px 10px",
};

export const flexStyle = { flex: 1, margin: 5, marginTop: 15, minWidth: 350 };
