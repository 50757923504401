import { Tabs } from "@mantine/core";
import { Box } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { AdminScreenTabs as AdminTabs } from "./AdminRoutes";

const AdminScreen = () => {
  const navigate = useNavigate();
  const { tab } = useParams();

  return (
    <Box bg="gray.100" minH="100vh" p={8}>
      <Tabs
        keepMounted={false}
        color="teal"
        value={tab}
        onTabChange={(value) => navigate(`/admin/${value}`)}
      >
        <Tabs.List>
          {AdminTabs.map((tab) => (
            <Tabs.Tab
              key={`x-${tab.value.replace(/_/g, "-")}`}
              value={tab.value.replace(/_/g, "-")}
              color={tab.color}
              disabled={tab.disabled}
            >
              <FormattedMessage id={tab.value} />
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {AdminTabs.map((tab) => (
          <Tabs.Panel
            key={`y-${tab.value.replace(/_/g, "-")}`}
            value={tab.value.replace(/_/g, "-")}
          >
            {tab.disabled !== true && tab.component}
          </Tabs.Panel>
        ))}
      </Tabs>
    </Box>
  );
};

export default AdminScreen;
