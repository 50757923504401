import React, { useState, useEffect } from "react";
import { getClients } from "../../functions/api";
import {
  Table,
  LoadingOverlay,
  Box,
  Title,
  ActionIcon,
  Pagination,
  Input,
} from "@mantine/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Eye, Search } from "tabler-icons-react";
import { PaginationItem } from "../../Components/PaginationItem";

export const ClientOverviewScreen = () => {
  const intl = useIntl();

  useEffect(() => {
    document.title = `${intl.formatMessage({
      id: "client_overview_screen",
    })} - SOMECRM`;
  }, []);

  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const itemsPerPage = 40;

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = clients?.filter(
    (item) =>
      item.c_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      item.c_surname?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      item.c_email?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      item.c_phone?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  useEffect(() => {
    getClients().then((response) => {
      setClients(response);
      setIsLoading(false);
    });
  }, []);

  const totalPages = filteredData
    ? filteredData?.length / itemsPerPage
    : clients?.length / itemsPerPage;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedClients = filteredData
    ? filteredData?.slice(startIndex, endIndex)
    : clients?.slice(startIndex, endIndex);

  const changeSearchTerm = (text) => {
    setCurrentPage(1);
    setSearchTerm(text);
  };

  return (
    <div>
      <div style={{ marginLeft: 15, marginRight: 15 }}>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Title order={2}>
            <FormattedMessage id="client_overview" />
          </Title>
        </div>
        <div style={{ marginTop: 15, marginBottom: 15 }}>
          <Input
            value={searchTerm}
            onChange={(e) => changeSearchTerm(e.target.value)}
            icon={<Search />}
            placeholder={intl.formatMessage({ id: "search_client" })}
          />
        </div>
        <div style={{ marginTop: 15, marginBottom: 15 }}>
          <PaginationItem
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      </div>
      <div
        style={{
          marginTop: 20,
          position: "relative",
          height: isLoading ? 600 : "auto",
        }}
      >
        <LoadingOverlay visible={isLoading} overlayBlur={2} />
        <div style={{ overflow: "auto" }}>
          <Table
            horizontalSpacing="sm"
            verticalSpacing="sm"
            striped
            highlightOnHover
            withBorder
            withColumnBorders
          >
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="name" />
                </th>
                <th>
                  <FormattedMessage id="surname" />
                </th>
                <th>
                  <FormattedMessage id="email" />
                </th>
                <th>
                  <FormattedMessage id="phone" />
                </th>
                <th>
                  <FormattedMessage id="creation_data" />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {displayedClients.map((client) => (
                <tr key={client.clientid}>
                  <td>
                    {client.c_name}{" "}
                    {client.c_bussiness_name ? (
                      <small>
                        <br />
                        <strong>
                          <FormattedMessage id="legal_name" />
                        </strong>
                        {client.c_bussiness_name}
                      </small>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>{client.c_surname}</td>
                  <td>{client.c_email}</td>
                  <td>{client.c_phone}</td>
                  <td>
                    {new Date(client.c_created).toLocaleString()}
                    <br />
                    {client.c_created_by}
                  </td>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    <a target="_blank" href={`/partner/${client.clientid}`}>
                      <ActionIcon color="green" size="lg" variant="light">
                        <Eye />
                      </ActionIcon>
                    </a>
                  </td>
                  {/* Add more Td components for other fields */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div
          style={{ marginTop: 20, display: "flex", justifyContent: "center" }}
        >
          <PaginationItem
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      </div>
    </div>
  );
};
