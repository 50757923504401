import {
  createStyles,
  Text,
  Avatar,
  Group,
  TypographyStylesProvider,
  Paper,
} from "@mantine/core";
import { TimeDiffToDisplay } from "../functions/misc";
import { useIntl } from 'react-intl';

const useStyles = createStyles((theme) => ({
  comment: {
    padding: 10,
  },

  body: {
    paddingLeft: 54,
    paddingTop: 1,
    fontSize: theme.fontSizes.sm,
  },

  content: {
    "& > p:last-child": {
      marginBottom: 0,
    },
  },
}));

export function Comment({ postedAt, body, author }) {
  const intl = useIntl();
  const { classes } = useStyles();
  return (
    <Paper withBorder radius="md" className={classes.comment}>
      <Group>
        <Avatar src={author.image} alt={author} radius="xl" />
        <div>
          <Text size="sm">{author}</Text>
          <Text size="xs" color="dimmed">
            {TimeDiffToDisplay(new Date(postedAt) - new Date())} {intl.formatMessage({id: "ago"})}
          </Text>
        </div>
      </Group>
      <TypographyStylesProvider className={classes.body}>
        <div
          className={classes.content}
          dangerouslySetInnerHTML={{ __html: body }}
        />
      </TypographyStylesProvider>
    </Paper>
  );
}
