import React, { useState, useRef, useEffect } from "react";

const DropDownButton = ({ options, onSelect, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const buttonRef = useRef(null);
  const triangleRef = useRef(null);
  const menuRef = useRef(null);

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    onSelect(option.action);
    setIsOpen(false);
  };

  useEffect(() => {
    options.map((x) => (x.selected ? setSelectedOption(x) : null));
  });

  const handleToggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleButtonClick = () => {
    if (!isOpen) {
      onClick(selectedOption.action);
    }
  };

  const handleCloseMenu = (event) => {
    if (
      buttonRef.current &&
      !buttonRef.current.contains(event.target) &&
      !triangleRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  const buttonStyle = {
    backgroundColor: "#FFFFFF",
    color: "#000000",
    border: "1px solid #C4C4C4",
    borderRadius: "4px",
    // padding: "0.5rem 1rem",
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
  };

  const triangleStyle = {
    border: "solid #fff",
    borderWidth: "0 2px 2px 0",
    display: "inline-block",
    padding: "3px",
    paddingBottom: isOpen ? "3px" : "5px",
    marginBottom: isOpen ? "" : "3px",
    transform: isOpen ? "rotate(-135deg)" : "rotate(45deg)",
    cursor: "pointer",
    marginLeft: 10,
    marginRight: 10,
    alignSelf: "center",
  };

  const dropdownOptionStyle = {
    padding: "0.5rem",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#F2F2F2",
    },
  };

  const menuStyle = {
    display: isOpen ? "block" : "none",
    position: "absolute",
    top: 35,
    left: 0,
    width: 160,
    zIndex: 1000,
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.3)",
    borderRadius: "4px",
    marginTop: "6px",
    padding: "0.5rem",
    maxHeight: "200px",
    overflowY: "auto",
  };

  return (
    <div style={buttonStyle} onBlur={handleCloseMenu}>
      <span
        style={{
          borderRight: "1px solid #ccc",
          paddingRight: 10,
          padding: "0.5rem 1rem",
          backgroundColor:
            selectedOption.action == "done"
              ? "#009e2a"
              : selectedOption.action == "event"
              ? "dodgerblue"
              : "#dc0000",
          color: "white",
        }}
      >
        <button ref={buttonRef} onClick={handleButtonClick}>
          {selectedOption.name}
        </button>
      </span>
      <span
        onClick={handleToggleMenu}
        style={{
          background:
            selectedOption.action == "done"
              ? "#007c25"
              : selectedOption.action == "event"
              ? "#2525e2d4"
              : "#8c0000",
          paddingTop: 8,
          paddingBottom: 8,
        }}
      >
        <span style={triangleStyle} ref={triangleRef}></span>
      </span>
      <div ref={menuRef} style={menuStyle}>
        <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
          {options.map((option, index) => (
            <li
              key={index}
              style={dropdownOptionStyle}
              onClick={() => handleSelectOption(option)}
            >
              {option.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DropDownButton;
