import { Card, Text, Badge } from "@mantine/core";
import { Comment } from "../Comment";
import { FormattedMessage, useIntl } from "react-intl";

const InvoiceCard = ({ invoice }) => {
  const intl = useIntl();
  const {
    created_on,
    due_date,
    invoice_name,
    note,
    paid_amount,
    paid_date,
    total_vat,
    user,
    status,
    extended_due,
  } = invoice;
  // Format dates using toLocaleDateString()
  const formattedCreatedOn = new Date(created_on).toLocaleDateString();
  const formattedDueDate = new Date(
    extended_due ? extended_due : due_date
  ).toLocaleDateString();
  const formattedPaidDate = paid_date
    ? new Date(paid_date).toLocaleDateString()
    : null;

  return (
    <Card
      key={invoice.invoice_id}
      shadow="sm"
      style={{ width: "100%", marginTop: 2, marginBottom: 5, padding: 8 }}
      className="rounded-lg overflow-hidden"
    >
      <a href={"/invoices/" + invoice.invoice_id}>
        <div className="p-3">
          <Text weight={600} size="lg" className="mb-1">
            {invoice_name}
          </Text>
          <div className="flex items-center justify-between mb-3">
            <Badge
              color={
                status == "void"
                  ? "red"
                  : paid_amount
                  ? paid_amount >= paid_amount
                    ? "green"
                    : "indigo"
                  : "red"
              }
              variant={status == "void" ? "filled" : "outline"}
            >
              {status == "void" ? (
                <FormattedMessage id="annuled" />
              ) : paid_amount ? (
                paid_amount >= paid_amount ? (
                  <FormattedMessage id="paid" />
                ) : (
                  <FormattedMessage id="partially" />
                )
              ) : (
                <FormattedMessage id="unpaid" />
              )}
            </Badge>
            <Text size="xs">{formattedCreatedOn}</Text>
          </div>
          <div className="flex items-center justify-between mb-3">
            <Text size="sm">
              {extended_due
                ? intl.formatMessage({ id: "extended" })
                : intl.formatMessage({ id: "due" })}{" "}
              {intl.formatMessage({ id: "date" })}
            </Text>
            <Text size="sm">{formattedDueDate}</Text>
          </div>
          <div className="flex items-center justify-between mb-3">
            <Text size="sm">{intl.formatMessage({ id: "total" })}</Text>
            <Text size="sm">{total_vat}</Text>
          </div>
          <div className="flex items-center justify-between mb-3">
            <Text size="sm">{intl.formatMessage({ id: "issued_by" })}</Text>
            <Text size="sm">{user ? user : invoice.issued_by}</Text>
          </div>
          {note && (
            <div className="bg-gray-100 p-2 rounded-lg mb-3">
              <Text size="sm">{note}</Text>
            </div>
          )}
          {paid_date && (
            <div className="flex items-center justify-between">
              <Text size="sm">{intl.formatMessage({ id: "paid_date" })}</Text>
              <Text size="sm">{formattedPaidDate}</Text>
            </div>
          )}
        </div>
        {invoice &&
          invoice.comments &&
          invoice.comments[0].created != null &&
          invoice.comments?.map((cm) => (
            <Comment
              key={cm.comment_id}
              postedAt={cm.created}
              body={cm.comment}
              author={cm?.name + " " + (cm?.surname != null ? cm.surname : "")}
            />
          ))}
      </a>
    </Card>
  );
};

export default InvoiceCard;
