const componentStyles = (theme) => ({
  buttonContainedDefault: {
    backgroundColor: "black", //theme.palette.dark.main,
    borderColor: "black", //theme.palette.dark.main,
    color: "white", //theme.palette.white.main,
    "&:hover": {
      backgroundColor: "black", //theme.palette.dark.dark,
      borderColor: "black", //theme.palette.dark.dark,
    },
  },
  buttonContainedPrimary: {
    backgroundColor: "dodgerblue", //theme.palette.primary.main,
    borderColor: "dodgerblue", //theme.palette.primary.main,
    color: "white", //theme.palette.white.main,
    "&:hover": {
      backgroundColor: "black", //theme.palette.primary.dark,
      borderColor: "black", //theme.palette.primary.dark,
    },
  },
  buttonContainedInfo: {
    backgroundColor: "orange", //theme.palette.info.main,
    borderColor: "orange", //theme.palette.info.main,
    color: "white", //theme.palette.white.main,
    "&:hover": {
      backgroundColor: "black", //theme.palette.info.dark,
      borderColor: "black", //theme.palette.info.dark,
    },
  },
  buttonContainedSuccess: {
    backgroundColor: "green", //theme.palette.success.main,
    borderColor: "green", //theme.palette.success.main,
    color: "white", //theme.palette.white.main,
    "&:hover": {
      backgroundColor: "black", //theme.palette.success.dark,
      borderColor: "black", //theme.palette.success.dark,
    },
  },
  buttonContainedError: {
    backgroundColor: "red", //theme.palette.error.main,
    borderColor: "red", //theme.palette.error.main,
    color: "white", //theme.palette.white.main,
    "&:hover": {
      backgroundColor: "grey", //theme.palette.error.dark,
      borderColor: "grey", //theme.palette.error.dark,
    },
  },
  buttonContainedWarning: {
    backgroundColor: "yellow", //theme.palette.warning.main,
    borderColor: "yellow", //theme.palette.warning.main,
    color: "white", //theme.palette.white.main,
    "&:hover": {
      backgroundColor: "orange", //theme.palette.warning.dark,
      borderColor: "orange", //theme.palette.warning.dark,
    },
  },
  buttonOutlineInfo: {
    color: "orange", //theme.palette.info.main,
    borderColor: "orange", //theme.palette.info.main,
    "&:hover": {
      backgroundColor: "orange", //theme.palette.info.main,
    },
  },
  buttonOutlineSuccess: {
    color: "green", //theme.palette.success.main,
    borderColor: "green", //theme.palette.success.main,
    "&:hover": {
      backgroundColor: "green", //theme.palette.success.main,
    },
  },
  buttonOutlineError: {
    color: "red", //theme.palette.error.main,
    borderColor: "red", //theme.palette.error.main,
    "&:hover": {
      backgroundColor: "red", //theme.palette.error.main,
    },
  },
  buttonOutlineWarning: {
    color: "yellow", //theme.palette.warning.main,
    borderColor: "yellow", //theme.palette.warning.main,
    "&:hover": {
      backgroundColor: "yellow", //theme.palette.warning.main,
    },
  },
  buttonActive: {
    backgroundColor: "black", //theme.palette.dark.dark,
    borderColor: "black", //theme.palette.dark.dark,
  },
  buttonActivePrimary: {
    backgroundColor: "black", //theme.palette.primary.dark,
    borderColor: "black", //theme.palette.primary.dark,
  },
  buttonActiveSecondary: {
    backgroundColor: "blue", //theme.palette.secondary.btnActive,
    borderColor: "blue", //theme.palette.secondary.btnActive,
  },
  buttonActiveInfo: {
    backgroundColor: "black", //theme.palette.info.dark,
    borderColor: "black", //theme.palette.info.dark,
  },
  buttonActiveSuccess: {
    backgroundColor: "black", //theme.palette.success.dark,
    borderColor: "black", //theme.palette.success.dark,
  },
  buttonActiveError: {
    backgroundColor: "grey", //theme.palette.error.dark,
    borderColor: "grey", //theme.palette.error.dark,
  },
  buttonActiveWarning: {
    backgroundColor: "orange", //theme.palette.warning.dark,
    borderColor: "orange", //theme.palette.warning.dark,
  },
  buttonDisabled: {
    opacity: ".65",
    boxShadow: "none!important",
    pointerEvents: "none",
  },
  butttonInfoContainedGroup: {
    backgroundColor: "orange", //theme.palette.info.main + "!important",
    borderColor: "orange", //theme.palette.info.main + "!important",
    color: "white", //theme.palette.white.main + "!important",
    "&:hover": {
      backgroundColor: "black", //theme.palette.info.dark + "!important",
      borderColor: "black", //theme.palette.info.dark + "!important",
    },
  },
  // use this when working with small buttons
  letterSpacingInherit: {
    letterSpacing: "inherit",
  },
  buttonContainedFacebook: {
    backgroundColor: "blue", // theme.palette.facebook.main,
    borderColor: "blue", // theme.palette.facebook.main,
    color: "white", //theme.palette.white.main,
    "&:hover": {
      backgroundColor: "darkblue", //theme.palette.facebook.dark,
      borderColor: "darkblue", //theme.palette.facebook.dark,
    },
  },
  buttonContainedTwitter: {
    backgroundColor: "blue", //theme.palette.twitter.main,
    borderColor: "blue", //theme.palette.twitter.main,
    color: "white", //theme.palette.white.main,
    "&:hover": {
      backgroundColor: "darkblue", //theme.palette.twitter.dark,
      borderColor: "darkblue", //theme.palette.twitter.dark,
    },
  },
  buttonContainedGoogle: {
    backgroundColor: "green", //theme.palette.google.main,
    borderColor: "green", //theme.palette.google.main,
    color: "white", //theme.palette.white.main,
    "&:hover": {
      backgroundColor: "green", //theme.palette.google.dark,
      borderColor: "green", //theme.palette.google.dark,
    },
  },
  buttonContainedInstagram: {
    backgroundColor: "pink", //theme.palette.instagram.main,
    borderColor: "pink", //theme.palette.instagram.main,
    color: "white", //theme.palette.white.main,
    "&:hover": {
      backgroundColor: "orange", //theme.palette.instagram.dark,
      borderColor: "orange", //theme.palette.instagram.dark,
    },
  },
  buttonContainedPinterest: {
    backgroundColor: "pink",//theme.palette.pinterest.main,
    borderColor: "pink",//theme.palette.pinterest.main,
    color: "white", //theme.palette.white.main,
    "&:hover": {
      backgroundColor: "pink",//theme.palette.pinterest.dark,
      borderColor: "pink",//theme.palette.pinterest.dark,
    },
  },
  buttonContainedYoutube: {
    backgroundColor: "red",//theme.palette.youtube.main,
    borderColor: "red",//theme.palette.youtube.main,
    color: "white", //theme.palette.white.main,
    "&:hover": {
      backgroundColor: "red",//theme.palette.youtube.dark,
      borderColor: "red",//theme.palette.youtube.dark,
    },
  },
  // buttonContainedVimeo: {
  //   backgroundColor: theme.palette.vimeo.main,
  //   borderColor: theme.palette.vimeo.main,
  //   color: "white", //theme.palette.white.main,
  //   "&:hover": {
  //     backgroundColor: theme.palette.vimeo.dark,
  //     borderColor: theme.palette.vimeo.dark,
  //   },
  // },
  // buttonContainedSlack: {
  //   backgroundColor: theme.palette.slack.main,
  //   borderColor: theme.palette.slack.main,
  //   color: "white", //theme.palette.white.main,
  //   "&:hover": {
  //     backgroundColor: theme.palette.slack.dark,
  //     borderColor: theme.palette.slack.dark,
  //   },
  // },
  // buttonContainedDribbble: {
  //   backgroundColor: theme.palette.dribbble.main,
  //   borderColor: theme.palette.dribbble.main,
  //   color: "white", //theme.palette.white.main,
  //   "&:hover": {
  //     backgroundColor: theme.palette.dribbble.dark,
  //     borderColor: theme.palette.dribbble.dark,
  //   },
  // },
  // buttonContainedGithub: {
  //   backgroundColor: theme.palette.github.main,
  //   borderColor: theme.palette.github.main,
  //   color: "white", //theme.palette.white.main,
  //   "&:hover": {
  //     backgroundColor: theme.palette.github.dark,
  //     borderColor: theme.palette.github.dark,
  //   },
  // },
  buttonIconOnly: {
    width: "2.375rem",
    height: "2.375rem",
    padding: "0",
    minWidth: "unset",
  },
});

export default componentStyles;
