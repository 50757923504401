import { useEffect, useState } from "react";
import CalendarView from "../../Components/Calendar";
import { useLocation } from "react-router-dom";

export default function CalendarScreen() {
  const [initDate, setInitDate] = useState(null);
  const [mode, setMode] = useState("month");

  const location = useLocation();
  let routes = location.pathname.split("/");

  useEffect(() => {
    if (routes[2]) {
      const dayData = routes[2].split("-");
      if (dayData[0] && dayData[1] && dayData[2]) {
        setMode("day");
        setInitDate(new Date(`${dayData[2]}-${dayData[1]}-${dayData[0]}`));
      }
    } else {
      setInitDate(new Date());
    }
  }, []);

  return (
    <CalendarView
      mode={mode}
      setMode={setMode}
      initDate={initDate}
      setInitDate={setInitDate}
    />
  );
}
