import { Text, TextInput } from "@mantine/core";

export const EditableLabels = ({
  edit,
  label,
  placeholder,
  data,
  type = "text",
  withAsterisk = false,
  onInputChange,
}) => {
  function handleChange(event) {
    onInputChange(event.target.value);
  }
  return (
    <>
      {edit ? (
        <TextInput
          type={type}
          placeholder={placeholder}
          label={label}
          withAsterisk={withAsterisk}
          value={data}
          onChange={handleChange}
        />
      ) : (
        <div>
          <Text style={{ fontWeight: 600, fontSize: 14, color: "gray" }}>
            {label}
          </Text>
          <Text style={{ fontSize: 14 }}>{data}</Text>
        </div>
      )}
    </>
  );
};
