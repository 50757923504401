import {
  Alert,
  Tabs,
  MultiSelect,
  Grid,
  Loader,
  Accordion,
  Button,
  Anchor,
  Badge,
  Select,
  Input,
  Dialog,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { AlertCircle, Pencil } from "tabler-icons-react";
import { DatePickerInput } from "@mantine/dates";
import {
  getLeadClientList,
  getReportCalls,
  getReportClientResults,
  getReportFilters,
  getReportInvoiceResults,
  getReportLeadResults,
  getReportTaskResults,
} from "../../functions/api";
import { CallCard, CallButton } from "../../Components";
import { useNavigate, useParams } from "react-router-dom";
import { getGroup, getUserId } from "../../functions/tokens";
import { useDisclosure } from "@mantine/hooks";
import { ModifyInvoiceModal } from "../Client/InvoicesPage";
import { FormattedMessage, useIntl } from "react-intl";
import { LeadListItem } from "../User/LeadScreen";
import { env } from "../../env";

const AdminReports = () => {
  const intl = useIntl();
  const [filters, setFilters] = useState();
  const navigate = useNavigate();
  const { tab } = useParams();

  const TAB_COMPONENTS = {
    clients: <ClientsReport filters={filters && filters} />,
    tasks: <TaskReport filters={filters && filters} />,
    calls: <CallsReport filters={filters && filters} />,
    invoices: <InvoicesReport filters={filters && filters} />,
    //maps: <MapReport filters={filters && filters} />,
    users: <UsersReport />,
    leads: <LeadsReport filters={filters && filters} />,
  };

  useEffect(() => {
    getReportFilters().then((x) => setFilters(x));
  }, []);

  return (
    <>
      <Alert
        icon={<AlertCircle size="2rem" />}
        title={intl.formatMessage({ id: "soon" })}
        color="green"
        style={{ marginBottom: 15 }}
      >
        <FormattedMessage id="wip" />
      </Alert>
      <Tabs
        value={tab}
        onTabChange={(value) => navigate(`/admin-reports/${value}`)}
        keepMounted={false}
        defaultValue="clients"
      >
        <Tabs.List>
          <Tabs.Tab value="clients">
            <FormattedMessage id="clients" />
          </Tabs.Tab>
          <Tabs.Tab value="tasks">
            <FormattedMessage id="tasks" />
          </Tabs.Tab>
          <Tabs.Tab value="calls">
            <FormattedMessage id="calls" />
          </Tabs.Tab>
          <Tabs.Tab value="invoices">
            <FormattedMessage id="invoices" />
          </Tabs.Tab>
          <Tabs.Tab value="maps" disabled>
            <FormattedMessage id="maps" />
          </Tabs.Tab>
          <Tabs.Tab value="users" disabled>
            <FormattedMessage id="users" />
          </Tabs.Tab>
          <Tabs.Tab value="leads">
            <FormattedMessage id="leads_overview" />
          </Tabs.Tab>
        </Tabs.List>{" "}
        {Object.keys(TAB_COMPONENTS).map((value) => (
          <Tabs.Panel key={value} value={value} pt="xs" disabled={!filters}>
            {TAB_COMPONENTS[value]}
          </Tabs.Panel>
        ))}
      </Tabs>
    </>
  );
};

export const stateObject = {
  clientStatus: [],
  whoCreated: [],
  source: [],
  closedReason1: [],
  creationRange: [null, null],
  closeRange: [null, null],
  hasTask: null,
  closedReason2: [],
  taskStatus: [],
  assignedTo: [],
  completionRange: [null, null],
  closedRange: [null, null],
  deadlineRange: [null, null],
  showBy: null,
  calledTo: [],
  whoCalled: [],
  callNr: [],
  callState: null,
  status: [],
  createdBy: [],
  madeFor: [],
  dueDateRange: [null, null],
  paidDateRange: [null, null],
  system: [],
  sortBy: [],
};

const ClientsReport = ({ filters }) => {
  const intl = useIntl();
  const [values, setValues] = useState(stateObject);

  const [results, setResults] = useState([]);
  const [openo, setOpeno] = useState();

  const handleChange = (name) => (value) => {
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const submitFilters = () => {
    getReportClientResults(values).then((x) => setResults(x));
  };

  return (
    <>
      <div style={{ padding: 10 }}>
        {!filters ? (
          <Loader />
        ) : (
          <Grid>
            <Grid.Col xs={6}>
              <MultiSelect
                searchable
                data={[
                  {
                    value: "closed",
                    label: intl.formatMessage({ id: "closed" }),
                  },
                  {
                    value: "active",
                    label: intl.formatMessage({ id: "active" }),
                  },
                ]}
                label={intl.formatMessage({ id: "client_status" })}
                placeholder={intl.formatMessage({ id: "select_status" })}
                value={values.clientStatus}
                onChange={handleChange("clientStatus")}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <MultiSelect
                searchable
                data={filters ? filters?.users : []}
                label={intl.formatMessage({ id: "client_creator" })}
                placeholder={intl.formatMessage({ id: "select_status" })}
                value={values.whoCreated}
                onChange={handleChange("whoCreated")}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <MultiSelect
                searchable
                data={filters?.sources}
                label={intl.formatMessage({ id: "client_source" })}
                placeholder={intl.formatMessage({ id: "select_source" })}
                value={values.source}
                onChange={handleChange("source")}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <MultiSelect
                searchable
                data={filters?.closes}
                label={intl.formatMessage({ id: "closing_reason" })}
                placeholder={intl.formatMessage({ id: "select_status" })}
                value={values.closedReason1}
                onChange={handleChange("closedReason1")}
              />
            </Grid.Col>
            <Grid.Col xs={4}>
              <DatePickerInput
                allowSingleDateInRange
                clearable
                type="range"
                label={intl.formatMessage({ id: "creation_range" })}
                placeholder={intl.formatMessage({ id: "pick_date_range" })}
                value={values.creationRange}
                onChange={handleChange("creationRange")}
                mx="auto"
              />
            </Grid.Col>
            <Grid.Col xs={4}>
              <DatePickerInput
                allowSingleDateInRange
                clearable
                type="range"
                label={intl.formatMessage({ id: "closure_range" })}
                placeholder={intl.formatMessage({ id: "pick_date_range" })}
                value={values.closeRange}
                onChange={handleChange("closeRange")}
                mx="auto"
              />
            </Grid.Col>
            <Grid.Col xs={4}>
              <MultiSelect
                searchable
                data={[
                  { value: "closed", label: "Closed" },
                  { value: "created", label: "Created" },
                ]}
                label="Group by"
                placeholder="Select grouping"
                value={values.showBy}
                onChange={handleChange("showBy")}
              />
            </Grid.Col>
            <Grid.Col xs={10}>
              <Button
                style={{ backgroundColor: "dodgerblue" }}
                onClick={() => submitFilters()}
              >
                <FormattedMessage id="submit" />
              </Button>
            </Grid.Col>
            <div style={{ width: "100%" }}>
              <Accordion value={openo} onChange={setOpeno}>
                {results &&
                  results.map((res) => (
                    <Accordion.Item
                      key={res.month ? res.month : "x"}
                      value={res.month ? res.month : "x"}
                    >
                      <Accordion.Control>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            {res.month
                              ? new Date(res.month)
                                  .toLocaleDateString("en-GB")
                                  .substring(3)
                              : "ungrouped"}
                          </span>{" "}
                          <span>{res.count}</span>
                        </div>
                      </Accordion.Control>
                      {openo == res.month &&
                        res.data.map((d) => {
                          return (
                            <Accordion.Panel key={d.clientid}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Anchor
                                  href={"/partner/" + d.clientid}
                                  target="_blank"
                                  style={{ minWidth: "15%" }}
                                >
                                  {d?.c_name} {d?.c_surname}
                                </Anchor>
                                <div style={{ width: "25%" }}>
                                  {d &&
                                    d.sources &&
                                    d.sources.map(
                                      (s) =>
                                        s?.source_name && (
                                          <Badge
                                            style={{
                                              backgroundColor: s?.source_color,
                                              color: s?.source_text_color,
                                              margin: 3,
                                            }}
                                          >
                                            {s?.source_name}
                                          </Badge>
                                        )
                                    )}
                                </div>
                                {d.c_created_by ? (
                                  <span style={{ color: "grey", fontSize: 14 }}>
                                    <FormattedMessage id="ar_created_by" />
                                    {d?.name} {d?.surname}
                                  </span>
                                ) : (
                                  ""
                                )}{" "}
                                {d.c_created?.substring(0, 10)}
                              </div>
                            </Accordion.Panel>
                          );
                        })}
                    </Accordion.Item>
                  ))}
              </Accordion>
            </div>
          </Grid>
        )}
      </div>
    </>
  );
};

const TaskReport = ({ filters }) => {
  const intl = useIntl();

  const task_status = [
    { value: "closed", label: intl.formatMessage({ id: "closed" }) },
    { value: "open", label: intl.formatMessage({ id: "open" }) },
    { value: "completed", label: intl.formatMessage({ id: "ar_completed" }) },
  ];

  const group_by = [
    { value: "closed", label: intl.formatMessage({ id: "closed" }) },
    { value: "created", label: intl.formatMessage({ id: "ar_created_cap" }) },
    { value: "completed", label: intl.formatMessage({ id: "ar_completed" }) },
  ];

  const [results, setResults] = useState([]);
  const [openo, setOpeno] = useState();
  const [values, setValues] = useState(stateObject);

  const submitFilters = () => {
    getReportTaskResults(values).then((x) => setResults(x));
  };

  const handleChange = (name) => (value) => {
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      <div style={{ padding: 10 }}>
        <Grid>
          <Grid.Col xs={4}>
            <MultiSelect
              searchable
              data={task_status}
              label={intl.formatMessage({ id: "task_status" })}
              placeholder={intl.formatMessage({ id: "select_status" })}
              value={values.taskStatus}
              onChange={handleChange("taskStatus")}
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <MultiSelect
              searchable
              data={filters ? filters?.users : []}
              label={intl.formatMessage({ id: "select_who_created" })}
              placeholder={intl.formatMessage({ id: "select_status" })}
              value={values?.whoCreated}
              onChange={handleChange("whoCreated")}
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <MultiSelect
              searchable
              data={filters ? filters?.users : []}
              label={intl.formatMessage({ id: "assigned_to" })}
              placeholder={intl.formatMessage({ id: "select_user" })}
              value={values?.assignedTo}
              onChange={handleChange("assignedTo")}
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <DatePickerInput
              allowSingleDateInRange
              clearable
              type="range"
              label={intl.formatMessage({ id: "creation_range" })}
              placeholder={intl.formatMessage({ id: "pick_date_range" })}
              value={values.creationRange}
              onChange={handleChange("creationRange")}
              mx="auto"
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <DatePickerInput
              allowSingleDateInRange
              clearable
              type="range"
              label={intl.formatMessage({ id: "completion_range" })}
              placeholder={intl.formatMessage({ id: "pick_date_range" })}
              value={values.completionRange}
              onChange={handleChange("completionRange")}
              mx="auto"
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <DatePickerInput
              allowSingleDateInRange
              clearable
              type="range"
              label={intl.formatMessage({ id: "closure_range" })}
              placeholder={intl.formatMessage({ id: "pick_date_range" })}
              value={values.closedRange}
              onChange={handleChange("closedRange")}
              mx="auto"
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <DatePickerInput
              allowSingleDateInRange
              clearable
              type="range"
              label={intl.formatMessage({ id: "deadline_range" })}
              placeholder={intl.formatMessage({ id: "pick_date_range" })}
              value={values.deadlineRange}
              onChange={handleChange("deadlineRange")}
              mx="auto"
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <MultiSelect
              searchable
              data={group_by}
              label={intl.formatMessage({ id: "group_by" })}
              placeholder={intl.formatMessage({ id: "select_grouping" })}
              value={values.showBy}
              onChange={handleChange("showBy")}
            />
          </Grid.Col>
        </Grid>
        <Button
          style={{
            backgroundColor: "dodgerblue",
            marginTop: 20,
            marginBottom: 20,
          }}
          onClick={() => submitFilters()}
        >
          <FormattedMessage id="submit" />
        </Button>
        <Accordion value={openo} onChange={setOpeno}>
          {results &&
            results.map((res) => (
              <Accordion.Item
                key={res.month ? res.month : "x"}
                value={res.month ? res.month : "x"}
              >
                <Accordion.Control>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>
                      {res.month ? (
                        new Date(res.month)
                          .toLocaleDateString("en-GB")
                          .substring(3)
                      ) : (
                        <FormattedMessage id="ungrouped" />
                      )}
                    </span>{" "}
                    <span>{res.count}</span>
                  </div>
                </Accordion.Control>
                {openo == (res.month ? res.month : "x") &&
                  res.data.map((d) => {
                    return (
                      <Accordion.Panel key={d.taskid}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Anchor
                            href={"/partner/" + d.clientid}
                            target="_blank"
                            style={{ minWidth: "15%" }}
                          >
                            {d?.task_name}
                          </Anchor>
                          {d.created_by && (
                            <span style={{ color: "grey", fontSize: 14 }}>
                              <FormattedMessage id="ar_created" />
                              {d?.created_by_name} {d?.created_by_surname}
                            </span>
                          )}{" "}
                          {d.created?.substring(0, 10)}
                        </div>
                      </Accordion.Panel>
                    );
                  })}
              </Accordion.Item>
            ))}
        </Accordion>
      </div>
    </>
  );
};

const CallsReport = ({ filters }) => {
  const intl = useIntl();
  const [values, setValues] = useState(stateObject);

  const [calls, setCalls] = useState([]);

  const submitFilters = () => {
    getReportCalls(values).then(({ calls }) => {
      setCalls(calls);
    });
  };
  const handleChange = (name) => (value) => {
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      <Grid>
        <Grid.Col xs={6}>
          <MultiSelect
            searchable
            data={filters ? filters?.users : []}
            label={intl.formatMessage({ id: "who_called" })}
            placeholder={intl.formatMessage({ id: "select_caller" })}
            value={values.whoCalled}
            onChange={handleChange("whoCalled")}
          />
        </Grid.Col>
        <Grid.Col xs={6}>
          <MultiSelect
            searchable
            data={[]}
            label={intl.formatMessage({ id: "client" })}
            placeholder={intl.formatMessage({ id: "select_client" })}
            value={values.calledTo}
            disabled
            onChange={handleChange("calledTo")}
          />
        </Grid.Col>
        <Grid.Col xs={6}>
          <MultiSelect
            label={intl.formatMessage({ id: "client_number" })}
            data={[]}
            placeholder={intl.formatMessage({ id: "enter_number" })}
            searchable
            creatable
            getCreateLabel={(query) => `+ ${query}`}
            onCreate={(query) => {
              const item = { value: query, label: query };
              handleChange("callNr")(item); // Fix: Call the handleChange function correctly
              return item;
            }}
          />
        </Grid.Col>

        <Grid.Col xs={6}>
          <Select
            label={intl.formatMessage({ id: "call_status" })}
            onChange={(x) => setValues({ ...values, ["callState"]: x })}
            value={values && values.callState}
            clearable
            data={[
              {
                value: "answer",
                label: intl.formatMessage({ id: "call_answered" }),
              },
              { value: "busy", label: intl.formatMessage({ id: "call_busy" }) },
            ]}
          />
        </Grid.Col>
      </Grid>
      <Button
        style={{
          backgroundColor: "dodgerblue",
          marginTop: 20,
          marginBottom: 20,
        }}
        onClick={() => submitFilters()}
      >
        <FormattedMessage id="submit" />
      </Button>
      <div style={{ paddingBottom: 20 }}> </div>
      {calls && <CallCard callLog={calls} />}
    </>
  );
};

export const InvoicesReport = ({ filters }) => {
  const intl = useIntl();

  const invoices_status = [
    { value: "paid", label: intl.formatMessage({ id: "pc_paid" }) },
    { value: "unpaid", label: intl.formatMessage({ id: "unpaid" }) },
    { value: "void", label: intl.formatMessage({ id: "void" }) },
  ];

  const invoices_group_by = [
    { value: "paid", label: intl.formatMessage({ id: "pc_paid" }) },
    { value: "deadline", label: intl.formatMessage({ id: "ar_deadline" }) },
    { value: "created", label: intl.formatMessage({ id: "ar_created_cap" }) },
  ];

  const [results, setResults] = useState([]);
  const [openo, setOpeno] = useState();
  const [values, setValues] = useState(stateObject);
  const [group, setGroup] = useState(getGroup());
  const [user, setUser] = useState(getUserId());
  const [dialogData, setDialogData] = useState();

  const [opened, { toggle, close }] = useDisclosure(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInvoiceClick = (invoice) => {
    setSelectedInvoice(invoice);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedInvoice(null);
    setIsModalOpen(false);
  };

  const submitFilters = () => {
    getReportInvoiceResults(values).then((x) => setResults(x));
  };

  useState(() => {
    const toFind = { ...values }; // Create a shallow copy of the values object

    if (group != 0) {
      // Check if group is not 0
      toFind.createdBy[0] = user; // Add the userId property to the toFind object
    }
    getReportInvoiceResults(toFind);
  }, [isModalOpen, selectedInvoice, opened, dialogData, group]);

  const handleChange = (name) => (value) => {
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      {selectedInvoice && (
        <ModifyInvoiceModal
          isModalOpen={isModalOpen}
          callBack={() => {
            submitFilters();
            closeModal();
          }}
          selectedInvoice={selectedInvoice}
        />
      )}
      <Dialog
        position={{ top: "50%", left: "50%" }}
        opened={opened}
        withCloseButton
        onClose={close}
        size="lg"
        radius="md"
      >
        <div style={{ display: "flex", flexDirection: "row", paddingTop: 10 }}>
          <Text size="sm" mb="xs" weight={500}>
            {intl.formatMessage({ id: "call" })}
            {dialogData && dialogData.c_phone}
          </Text>
          <CallButton
            client={
              dialogData && {
                ...dialogData,
                c_name: dialogData.issued_to,
                c_surname: "",
              }
            }
          />
        </div>
      </Dialog>
      <div style={{ padding: 10 }}>
        <Grid>
          <Grid.Col xs={4}>
            <MultiSelect
              searchable
              data={invoices_status}
              label={intl.formatMessage({ id: "status" })}
              placeholder={intl.formatMessage({ id: "select_status" })}
              value={values?.status}
              onChange={handleChange("status")}
            />
          </Grid.Col>
          {group == 0 && (
            <Grid.Col xs={4}>
              <MultiSelect
                searchable
                data={filters ? filters?.users : []}
                label={intl.formatMessage({ id: "ar_created_by_cap" })}
                placeholder={intl.formatMessage({ id: "select_users" })}
                value={values?.createdBy}
                onChange={handleChange("createdBy")}
              />
            </Grid.Col>
          )}
          <Grid.Col xs={4}>
            <DatePickerInput
              allowSingleDateInRange
              clearable
              type="range"
              label={intl.formatMessage({ id: "creation_range" })}
              placeholder={intl.formatMessage({ id: "pick_date_range" })}
              value={values?.creationRange}
              onChange={handleChange("creationRange")}
              mx="auto"
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <DatePickerInput
              allowSingleDateInRange
              clearable
              type="range"
              label={intl.formatMessage({ id: "due_date_range" })}
              placeholder={intl.formatMessage({ id: "pick_date_range" })}
              value={values?.dueDateRange}
              onChange={handleChange("dueDateRange")}
              mx="auto"
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <DatePickerInput
              allowSingleDateInRange
              clearable
              type="range"
              label={intl.formatMessage({ id: "paid_date_range" })}
              placeholder={intl.formatMessage({ id: "pick_date_range" })}
              value={values?.paidDateRange}
              onChange={handleChange("paidDateRange")}
              mx="auto"
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <Select
              // searchable
              clearable
              data={[
                {
                  label: intl.formatMessage({ id: "sort_paid_desc" }),
                  value: "paid-desc",
                },
                // { label: "Apmaksas datuma augoši", value: "paid-asc" },
                // { label: "Izveides datuma augoši", value: "created-asc" },
                {
                  label: intl.formatMessage({ id: "sort_created_desc" }),
                  value: "created-desc",
                },
              ]}
              label={intl.formatMessage({ id: "sort_by" })}
              placeholder={intl.formatMessage({ id: "select_sorting" })}
              value={values?.sortBy}
              onChange={handleChange("sortBy")}
            />
          </Grid.Col>
          <Grid.Col xs={8}>
            <Input
              placeholder={intl.formatMessage({ id: "search_invoices" })}
              value={values?.search}
              onChange={(e) => handleChange("search")(e.target.value)}
              variant="default"
              size="sm"
            />
          </Grid.Col>
        </Grid>
        <Button
          style={{
            backgroundColor: "dodgerblue",
            marginTop: 20,
            marginBottom: 20,
          }}
          onClick={submitFilters}
        >
          <FormattedMessage id="submit" />
        </Button>
        <Accordion value={openo} onChange={setOpeno}>
          {results &&
            results.map((res) => (
              <Accordion.Item
                key={res.month ? res.month : "x"}
                value={res.month ? res.month : "x"}
              >
                <Accordion.Control>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>
                      {res.month ? (
                        new Date(res.month)
                          .toLocaleDateString("en-GB")
                          .substring(3)
                      ) : (
                        <FormattedMessage id="ungrouped" />
                      )}
                    </span>{" "}
                    <span>{res.count}</span>
                  </div>
                </Accordion.Control>
                {openo == (res.month ? res.month : "x") &&
                  res.data.map((d) => {
                    const isPaid = d.status === "paid";
                    const isPastDue =
                      d.status !== "paid" &&
                      d.due_date &&
                      new Date(d.extended_due ? d.extended_due : d.due_date) <
                        new Date();

                    const panelStyle = {
                      textDecoration:
                        d.status == "void" ? "line-through" : "inherit",
                      textDecorationColor:
                        d.status == "void" ? "grey" : "inherit",
                      backgroundColor:
                        d.status == "void"
                          ? "inherit"
                          : isPaid
                          ? "lightgreen"
                          : isPastDue
                          ? "pink"
                          : "inherit",
                    };

                    return (
                      <Accordion.Panel key={d.invoice_id} style={panelStyle}>
                        <div
                          style={{
                            display: "flex",
                            textAlign: "left",
                            justifyContent: "space-between",
                          }}
                          // onClick={() => openDialog(d)}
                        >
                          <div>
                            <Anchor
                              href={"/invoices/" + d.invoice_id}
                              target="_blank"
                              style={{ minWidth: "15%" }}
                            >
                              {d.invoice_name} {"  "}
                              <Badge
                                color={
                                  d.status == "void"
                                    ? "red"
                                    : d.status == "paid"
                                    ? "green"
                                    : d.status == "sent"
                                    ? "dodgerblue"
                                    : ""
                                }
                              >
                                {d.status}
                              </Badge>
                            </Anchor>
                            <div style={{ fontSize: 14 }}>
                              <FormattedMessage id="issued_by" />
                              {d.created_by_name} {d.created_by_surname}
                            </div>
                          </div>
                          {d.created_by_name && (
                            <span
                              style={{ color: "grey", fontSize: 14 }}
                            ></span>
                          )}{" "}
                          <Anchor
                            href={"/partner/" + d.made_for}
                            target="_blank"
                            style={{ width: 200, textAlign: "left" }}
                          >
                            {d?.c_name} {d?.c_surname}
                          </Anchor>
                          <div style={{ width: 170 }}>
                            <div>
                              <small>
                                <FormattedMessage id="ar_paid" />
                              </small>
                              {d?.paid_date}
                            </div>
                            <div>
                              <small>
                                <FormattedMessage id="ar_due" />
                              </small>
                              {d?.extended_due ? d?.extended_due : d?.due_date}
                            </div>
                          </div>
                          <div style={{ width: 170 }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <small>{d.created_on?.substring(0, 10)}</small>
                              <Pencil
                                style={{ cursor: "pointer", marginLeft: 20 }}
                                onClick={() => handleInvoiceClick(d)}
                              />
                            </div>
                            <div
                              style={{ textAlign: "right", paddingRight: 45 }}
                            >
                              <small>
                                <span style={{ fontSize: 12 }}>
                                  <FormattedMessage id="ar_paid" />{" "}
                                </span>
                                {d.paid_amount && d.paid_amount + "€"}
                              </small>
                            </div>
                          </div>
                        </div>
                      </Accordion.Panel>
                    );
                  })}
              </Accordion.Item>
            ))}
        </Accordion>
      </div>
    </>
  );
};

const LeadsReport = ({ filters }) => {
  const intl = useIntl();
  const [values, setValues] = useState(stateObject);

  const [results, setResults] = useState([]);
  const [openo, setOpeno] = useState();

  const [modeList, setModeList] = useState();

  useEffect(() => {
    getLeadClientList().then((x) => {
      setModeList(x);
    });
  }, []);

  const handleChange = (name) => (value) => {
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const submitFilters = () => {
    getReportLeadResults(values).then((x) => setResults(x));
  };
  return (
    <>
      <div style={{ padding: 10 }}>
        {!filters ? (
          <Loader />
        ) : (
          <Grid>
            <Grid.Col xs={6}>
              <MultiSelect
                searchable
                data={filters ? filters?.users : []}
                label={intl.formatMessage({ id: "client_creator" })}
                placeholder={intl.formatMessage({ id: "select_status" })}
                value={values.whoCreated}
                onChange={handleChange("whoCreated")}
              />
            </Grid.Col>
            <Grid.Col xs={4}>
              <DatePickerInput
                allowSingleDateInRange
                clearable
                type="range"
                label={intl.formatMessage({ id: "creation_range" })}
                placeholder={intl.formatMessage({ id: "pick_date_range" })}
                value={values.creationRange}
                onChange={handleChange("creationRange")}
                mx="auto"
              />
            </Grid.Col>
            <Grid.Col xs={4}>
              <MultiSelect
                searchable
                data={env.LEADLIST}
                label={intl.formatMessage({ id: "system" })}
                placeholder={intl.formatMessage({ id: "select_system" })}
                value={values?.system}
                onChange={handleChange("system")}
              />
            </Grid.Col>
            <Grid.Col xs={10}>
              <Button
                style={{ backgroundColor: "dodgerblue" }}
                onClick={() => submitFilters()}
              >
                <FormattedMessage id="submit" />
              </Button>
            </Grid.Col>
            <div style={{ width: "100%" }}>
              <Accordion value={openo} onChange={setOpeno}>
                {results.map((res) => (
                  <Accordion.Item
                    key={
                      res.month ? (
                        res.month
                      ) : (
                        <FormattedMessage id="ungrouped" />
                      )
                    }
                    value={
                      res.month ? (
                        res.month
                      ) : (
                        <FormattedMessage id="ungrouped" />
                      )
                    }
                  >
                    <Accordion.Control>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* <span>{res.month.substring(0, 7)}</span>{" "} */}
                        <span>
                          {res.month ? (
                            new Date(res.month)
                              .toLocaleDateString("en-GB")
                              .substring(3)
                          ) : (
                            <FormattedMessage id="without_date" />
                          )}
                        </span>{" "}
                        <span>{res.count}</span>
                      </div>
                    </Accordion.Control>
                    {openo ==
                      (res.month ? (
                        res.month
                      ) : (
                        <FormattedMessage id="ungrouped" />
                      )) &&
                      res.data.map((d) => {
                        return (
                          <Accordion.Panel key={d.lead_id}>
                            <LeadListItem d={d} modeList={modeList} />
                          </Accordion.Panel>
                        );
                      })}
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </Grid>
        )}
      </div>
    </>
  );
};

const UsersReport = () => {
  return <></>;
};

export default AdminReports;
