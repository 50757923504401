import { Card, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { ZoomIn } from "tabler-icons-react";

export const EmailList = ({ emails }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [zoomed, setZoomed] = useState();

  const setZoom = (mail) => {
    setZoomed(mail);
    open();
  };

  return (
    <>
      <Modal opened={opened} size="xl" onClose={close} title={zoomed?.subject}>
        <Card withBorder>
          <Card withBorder mt={3} style={{ padding: 8 }}>
            <FormattedMessage id="from" />:{" "}
            <MailToButton mail={zoomed?.e_from} />
          </Card>
          <Card withBorder mt={3} style={{ padding: 8 }}>
            <FormattedMessage id="to" />: <MailToButton mail={zoomed?.e_to} />
          </Card>
          {zoomed?.cc && (
            <Card withBorder mt={3} style={{ padding: 8 }}>
              cc: <MailToButton mail={zoomed?.e_cc} />
            </Card>
          )}
          <Card withBorder mt={3} style={{ padding: 8 }}>
            <div style={{ fontWeight: 600, marginTop: 10 }}>
              {zoomed?.subject}
            </div>
            <div
              style={{ marginTop: 5 }}
              dangerouslySetInnerHTML={{ __html: zoomed?.body }}
            ></div>
          </Card>
          {/* <Button style={{ backgroundColor: "dodgerblue", marginTop: 10 }}>
            Reply
          </Button> */}
        </Card>
      </Modal>
      {emails.map((mail) => {
        const mailTime = new Date(mail.date);
        return (
          <Card withBorder key={mail.e_id}>
            <div
              style={{
                fontSize: 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>{mailTime.toLocaleTimeString()}</div>{" "}
              <div>{mailTime.toLocaleDateString()}</div>
            </div>
            <div
              style={{
                // display: "flex",
                // justifyContent: "space-between",
                fontSize: 12,
              }}
            >
              <div>
                <div style={{ fontSize: 10 }}>
                  <FormattedMessage id="from" />:{" "}
                  <MailToButton mail={mail.e_from} />
                </div>
              </div>
              <div>
                <div style={{ fontSize: 10 }}>
                  <FormattedMessage id="to" />:{" "}
                  <MailToButton mail={mail.e_to} />
                </div>
              </div>
              {mail.e_cc && (
                <div>
                  <div style={{ fontSize: 10 }}>
                    cc: <MailToButton mail={mail.e_cc} />
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontWeight: 600 }}>{mail.subject}</div>
              <ZoomIn
                style={{ cursor: "pointer" }}
                color="dodgerblue"
                size={18}
                onClick={() => setZoom(mail)}
              />
            </div>
            <div
              style={{ fontSize: 14 }}
              dangerouslySetInnerHTML={{ __html: mail.body }}
            ></div>
          </Card>
        );
      })}
    </>
  );
};

export const MailToButton = ({ mail }) => {
  return (
    <a style={{ color: "dodgerblue" }} href={`mailto:${mail}`}>
      {mail}
    </a>
  );
};
