import { Fragment, useEffect, useRef, useState } from "react";
import {
  getReportFilters,
  marketingCreateTemplate,
  marketingGetAllTemplates,
  marketingGetTemplate,
} from "../../functions/api";
import {
  Box,
  Button,
  Card,
  Grid,
  Loader,
  LoadingOverlay,
  MultiSelect,
  Tabs,
  TextInput,
  Textarea,
  Title,
} from "@mantine/core";
import { Route, useNavigate, useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { DatePickerInput } from "@mantine/dates";
import { stateObject } from "./AdminReports";
import EmailEditor, { EditorRef, EmailEditorProps } from "react-email-editor";
import sample from "./sample.json";

const NewButton = ({ href }) => {
  return (
    <a
      style={{
        backgroundColor: "dodgerblue",
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        color: "white",
        borderRadius: 5,
        cursor: "pointer",
      }}
      href={href}
    >
      New
    </a>
  );
};

export const MarketingScreenRoutes = () => {
  return (
    <>
      <Route exct path="/marketing/:tab" element={<MarketingScreen />} />
      {MarketingTabs.map((route) => (
        <Fragment key={route.value}>
          {route.disabled !== true && (
            <Route
              exact
              path={`/marketing/${route.value.replace(/_/g, "-")}/:key`}
              element={route.route ? route.route : route.component}
            />
          )}
        </Fragment>
      ))}
    </>
  );
};

export const MarketingCampaignScreen = () => {
  const { key } = useParams();
  const [values, setValues] = useState(stateObject);

  const navigate = useNavigate();
  if (key == "new") {
    return <></>;
  }

  return (
    <Card withBorder mt={10}>
      <NewButton href="campaigns/new" />
    </Card>
  );
};

export const MarketingScreen = () => {
  const { tab } = useParams();
  const navigate = useNavigate();

  return (
    <Box bg="gray.100" minH="100vh" p={8}>
      <Tabs
        keepMounted={false}
        color="teal"
        value={tab}
        onTabChange={(value) => navigate(`/marketing/${value}`)}
      >
        <Tabs.List>
          {MarketingTabs.map((tab) => (
            <Tabs.Tab
              value={tab.value.replace(/_/g, "-")}
              color={tab.color}
              disabled={tab.disabled}
            >
              <FormattedMessage id={tab.value} />
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {MarketingTabs.map((tab) => (
          <Tabs.Panel value={tab.value.replace(/_/g, "-")}>
            {tab.disabled !== true && tab.component}
          </Tabs.Panel>
        ))}
      </Tabs>
    </Box>
  );
};

export const MarketingClientList = () => {
  const { key } = useParams();
  const intl = useIntl();
  const [values, setValues] = useState(stateObject);

  const [filters, setFilters] = useState(null);

  useEffect(() => {
    getReportFilters().then((x) => setFilters(x));
  }, []);

  if (key == "new") {
    const handleChange = (name) => (value) => {
      setValues((prevState) => ({ ...prevState, [name]: value }));
    };

    const submitFilters = () => {
      console.log(values);
      //getReportClientResults(values).then((x) => setResults(x));
    };

    return (
      <>
        {!filters ? (
          <Loader />
        ) : (
          <Card withBorder mt={10}>
            <Title order={3}>
              {intl.formatMessage({ id: "include_in_campaign" })}
              {/* A way to add custom emails to the list */}
            </Title>
            <Grid>
              <Grid.Col xs={6}>
                <MultiSelect
                  searchable
                  data={[
                    {
                      value: "closed",
                      label: intl.formatMessage({ id: "closed" }),
                    },
                    {
                      value: "active",
                      label: intl.formatMessage({ id: "active" }),
                    },
                  ]}
                  label={intl.formatMessage({ id: "client_status" })}
                  placeholder={intl.formatMessage({ id: "select_status" })}
                  value={values.clientStatus}
                  onChange={handleChange("clientStatus")}
                />
              </Grid.Col>

              <Grid.Col xs={6}>
                <MultiSelect
                  searchable
                  data={[
                    {
                      value: "all",
                      label: intl.formatMessage({ id: "all" }),
                    },
                    {
                      value: "physcial",
                      label: intl.formatMessage({ id: "physcial" }),
                    },
                    {
                      value: "legal",
                      label: intl.formatMessage({ id: "legal" }),
                    },
                    {
                      value: "unmarked",
                      label: intl.formatMessage({ id: "unmarked" }),
                    },
                  ]}
                  label={intl.formatMessage({ id: "client_legal" })}
                  placeholder={intl.formatMessage({ id: "select_status" })}
                  value={values.legal}
                  onChange={handleChange("legal")}
                />
              </Grid.Col>

              <Grid.Col xs={6}>
                <MultiSelect
                  searchable
                  data={filters ? filters?.users : []}
                  label={intl.formatMessage({ id: "client_creator" })}
                  placeholder={intl.formatMessage({ id: "select_status" })}
                  value={values.whoCreated}
                  onChange={handleChange("whoCreated")}
                />
              </Grid.Col>
              <Grid.Col xs={6}>
                <MultiSelect
                  searchable
                  data={filters?.sources}
                  label={intl.formatMessage({ id: "client_source" })}
                  placeholder={intl.formatMessage({ id: "select_source" })}
                  value={values.source}
                  onChange={handleChange("source")}
                />
              </Grid.Col>
              <Grid.Col xs={6}>
                <MultiSelect
                  searchable
                  data={filters?.closes}
                  label={intl.formatMessage({ id: "closing_reason" })}
                  placeholder={intl.formatMessage({ id: "select_status" })}
                  value={values.closedReason1}
                  onChange={handleChange("closedReason1")}
                />
              </Grid.Col>
              <Grid.Col xs={4}>
                <DatePickerInput
                  allowSingleDateInRange
                  clearable
                  type="range"
                  label={intl.formatMessage({ id: "creation_range" })}
                  placeholder={intl.formatMessage({ id: "pick_date_range" })}
                  value={values.creationRange}
                  onChange={handleChange("creationRange")}
                  mx="auto"
                />
              </Grid.Col>
              <Grid.Col xs={4}>
                <DatePickerInput
                  allowSingleDateInRange
                  clearable
                  type="range"
                  label={intl.formatMessage({ id: "closure_range" })}
                  placeholder={intl.formatMessage({ id: "pick_date_range" })}
                  value={values.closeRange}
                  onChange={handleChange("closeRange")}
                  mx="auto"
                />
              </Grid.Col>
              {/* <Grid.Col xs={4}>
                <MultiSelect
                  searchable
                  data={[
                    { value: "closed", label: "Closed" },
                    { value: "created", label: "Created" },
                  ]}
                  label="Group by"
                  placeholder="Select grouping"
                  value={values.showBy}
                  onChange={handleChange("showBy")}
                />
              </Grid.Col> */}
              <Grid.Col xs={12}>
                <Title order={3}>
                  {intl.formatMessage({ id: "exclude_in_campaign" })}
                </Title>
              </Grid.Col>

              <Grid.Col xs={6}>
                <MultiSelect
                  searchable
                  data={[
                    {
                      value: "closed",
                      label: intl.formatMessage({ id: "closed" }),
                    },
                    {
                      value: "active",
                      label: intl.formatMessage({ id: "active" }),
                    },
                  ]}
                  label={intl.formatMessage({ id: "client_status" })}
                  placeholder={intl.formatMessage({ id: "select_status" })}
                  value={values.exclude_client_status}
                  onChange={handleChange("exclude_client_status")}
                />
              </Grid.Col>

              <Grid.Col xs={6}>
                <MultiSelect
                  searchable
                  data={[
                    {
                      value: "all",
                      label: intl.formatMessage({ id: "all" }),
                    },
                    {
                      value: "physcial",
                      label: intl.formatMessage({ id: "physcial" }),
                    },
                    {
                      value: "legal",
                      label: intl.formatMessage({ id: "legal" }),
                    },
                    {
                      value: "unmarked",
                      label: intl.formatMessage({ id: "unmarked" }),
                    },
                  ]}
                  label={intl.formatMessage({ id: "client_legal" })}
                  placeholder={intl.formatMessage({ id: "select_status" })}
                  value={values.exclude_legal}
                  onChange={handleChange("exclude_legal")}
                />
              </Grid.Col>

              <Grid.Col xs={6}>
                <MultiSelect
                  searchable
                  data={filters ? filters?.users : []}
                  label={intl.formatMessage({ id: "client_creator" })}
                  placeholder={intl.formatMessage({ id: "select_status" })}
                  value={values.exclude_whoCreated}
                  onChange={handleChange("exclude_whoCreated")}
                />
              </Grid.Col>
              <Grid.Col xs={6}>
                <MultiSelect
                  searchable
                  data={filters?.sources}
                  label={intl.formatMessage({ id: "client_source" })}
                  placeholder={intl.formatMessage({ id: "select_source" })}
                  value={values.exclude_source}
                  onChange={handleChange("exclude_source")}
                />
              </Grid.Col>
              <Grid.Col xs={6}>
                <MultiSelect
                  searchable
                  data={filters?.closes}
                  label={intl.formatMessage({ id: "closing_reason" })}
                  placeholder={intl.formatMessage({ id: "select_status" })}
                  value={values.exclude_closedReason1}
                  onChange={handleChange("exclude_closedReason1")}
                />
              </Grid.Col>
              <Grid.Col xs={4}>
                <DatePickerInput
                  allowSingleDateInRange
                  clearable
                  type="range"
                  label={intl.formatMessage({ id: "creation_range" })}
                  placeholder={intl.formatMessage({ id: "pick_date_range" })}
                  value={values.exclude_creationRange}
                  onChange={handleChange("exclude_creationRange")}
                  mx="auto"
                />
              </Grid.Col>
              <Grid.Col xs={4}>
                <DatePickerInput
                  allowSingleDateInRange
                  clearable
                  type="range"
                  label={intl.formatMessage({ id: "closure_range" })}
                  placeholder={intl.formatMessage({ id: "pick_date_range" })}
                  value={values.exclude_closeRange}
                  onChange={handleChange("exclude_closeRange")}
                  mx="auto"
                />
              </Grid.Col>

              <Grid.Col xs={10}>
                <Button
                  style={{ backgroundColor: "dodgerblue" }}
                  onClick={() => submitFilters()}
                >
                  <FormattedMessage id="submit" />
                </Button>
              </Grid.Col>
            </Grid>
          </Card>
        )}
      </>
    );
  }

  return (
    <Card withBorder mt={10}>
      <NewButton href="client-list/new" />
    </Card>
  );
};

export const MarketingTemplateScreen = () => {
  const { key } = useParams();
  const intl = useIntl();
  const [values, setValues] = useState();
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);

  const [temp, setTemp] = useState(null);
  const emailEditorRef = useRef(null);

  const [preview, setPreview] = useState(false);

  useEffect(() => {
    if (key && key != "new") {
      marketingGetTemplate({ id: key }).then(async (x) => {
        console.log(x);
        setTitle(x.title);
        setDescription(x.description);
        const fillData = { ...x, json: await JSON.parse(x.json) };
        console.log(fillData);
        setTemp(fillData);
        setLoading(false);
      });
    } else if (!key) {
      setLoading(false);
      marketingGetAllTemplates().then((x) => setTemp(x));
    } else {
      setLoading(false);
    }
  }, []);

  if (key) {
    const onDesignLoad = (data) => {};

    const onLoad = async (unlayer) => {
      if (!loading) {
        unlayer.addEventListener("design:loaded", onDesignLoad);
        // unlayer.loadDesign(
        //   key == "new" ? sample : await JSON?.parse(await temp?.json)
        // );
        unlayer.loadDesign(key == "new" ? sample : temp.json);
      }
    };

    const onReady = (unlayer) => {};

    const exportHtml = () => {
      const unlayer = emailEditorRef.current?.editor;

      unlayer?.exportHtml((data) => {
        const { design, html } = data;
        const sending = {
          title: title,
          description: description,
          json: design,
          html: html,
          id: key && key != "new" ? key : null,
        };
        marketingCreateTemplate(sending).then((x) => {
          if (key == "new") {
            window.location.href = `/marketing/email-templates/${x[0].template_id}`;
          }
        });
      });
    };
    if (loading) {
      return <LoadingOverlay />;
    }
    return (
      <div style={{ marginTop: 20 }}>
        <Grid style={{ margin: 10 }}>
          <Grid.Col xs={6}>
            <TextInput
              label={intl.formatMessage({ id: "template_title" })}
              name="title"
              value={title}
              onChange={(x) => setTitle(x.target.value)}
            />
          </Grid.Col>
          <Grid.Col xs={6}>
            <Textarea
              label={intl.formatMessage({ id: "template_description" })}
              autosize
              minRows={2}
              name="title"
              value={description}
              onChange={(x) => setDescription(x.target.value)}
            />
          </Grid.Col>
          <Grid.Col xs={12}>
            <Button
              onClick={exportHtml}
              style={{ backgroundColor: "dodgerblue", float: "right" }}
            >
              {intl.formatMessage({ id: "save_template" })}
            </Button>
          </Grid.Col>
        </Grid>
        <div
          style={{
            position: "relative",
            margin: 0,
            display: "flex",
            height: "75vh",
            marginTop: 10,
          }}
        >
          <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
        </div>
      </div>
    );
  }

  return (
    <Card withBorder mt={10}>
      <NewButton href="email-templates/new" />
      <div style={{ marginTop: 10 }}>
        {temp?.map((x) => (
          <a href={`/marketing/email-templates/${x.template_id}`}>
            <Card withBorder>
              <Title order={3}>{x?.title ? x?.title : "Untitled"}</Title>
              {x?.description}
            </Card>
          </a>
        ))}
      </div>
    </Card>
  );
};

export const MarketingItem = () => {
  const [filters, setFilters] = useState();
  //last task ->
  // closed ->
  useEffect(() => {
    getReportFilters().then((x) => {
      setFilters(x);
    });
  }, []);
  return <></>;
};

export const MarketingTabs = [
  {
    value: "client_list",
    color: "green",
    disabled: false,
    component: <MarketingClientList />,
  },
  {
    value: "campaigns",
    color: "blue",
    disabled: false,
    component: <MarketingCampaignScreen />,
  },
  {
    value: "email_templates",
    color: "red",
    disabled: false,
    component: <MarketingTemplateScreen />,
  },
];
