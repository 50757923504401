import { useState, useEffect } from "react";
import {
  useMantineTheme,
  Text,
  TextInput,
  FileInput,
  Button,
} from "@mantine/core";
import { getClients, sendFile } from "../../functions/api";
import { AutoSelect } from "../../Components";
import { FormattedMessage, useIntl } from "react-intl";

function AddDocumentScreen({ preclient }) {
  const intl = useIntl();
  const theme = useMantineTheme();
  const [client, setClient] = useState("");
  const [file, setFile] = useState(null);
  const [name, setName] = useState(null);

  useEffect(() => {
    if (preclient) {
      setClient(preclient);
    }
  }, []);

  const submitCreateInvoice = async () => {
    if (!file) {
      return window.alert(intl.formatMessage({ id: "add_file" }));
    }
    if (!name) {
      return window.alert(intl.formatMessage({ id: "add_name" }));
    }

    if (file) {
      sendFile(file, { name, client }).then((x) => {
        x.docId ? window.location.reload(false) : window.alert("error!");
      });
    }
  };

  return (
    <div style={{ padding: theme.spacing.md }}>
      <Text style={{ fontSize: theme.fontSizes.xl }}>
        {intl.formatMessage({ id: "add_file" })}
      </Text>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing.sm,
          marginTop: theme.spacing.md,
        }}
      >
        <AutoSelect
          title={intl.formatMessage({ id: "select_client" })}
          placeholder={intl.formatMessage({ id: "search_client" })}
          preselected={preclient ? preclient : null}
          getData={(data) => getClients(data, true)}
          callBack={setClient}
        />
        <TextInput
          label={intl.formatMessage({ id: "file_name" })}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <FileInput
          label={intl.formatMessage({ id: "upload_file" })}
          placeholder={intl.formatMessage({ id: "upload_file" })}
          value={file}
          onChange={setFile}
        />
        <Button
          variant="filled"
          onClick={() => submitCreateInvoice()}
          style={{ backgroundColor: "blue" }}
          fullWidth
        >
          <FormattedMessage id="upload_file" />
        </Button>
      </div>
    </div>
  );
}

export default AddDocumentScreen;
