export const labelStyle = (background, text) => {
  return {
    backgroundColor: background ? background : "#e3f2fd",
    // border: "1px solid #90caf9",
    // borderRadius: "4px",
    color: text ? text : "#2196f3",
    margin: 3,
    // fontSize: "16px",
    // fontWeight: "bold",
    // padding: "8px 16px",
    // textAlign: "center",
    // marginTop: 5,
  };
};
