import { useEffect, useState } from "react";
import {
  AppShell,
  Navbar,
  Header,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
  UnstyledButton,
  Avatar,
  Group,
  Title,
  Grid,
  TextInput,
  Select,
  ColorInput,
  Textarea,
} from "@mantine/core";
import { FormattedMessage, useIntl } from "react-intl";

const UnstyledSettingButton = ({
  title,
  description,
  onClick,
  link,
  color,
  disabeld = false,
}) => {
  const words = title.split(" ");

  // Get the first letter of the first two words
  const firstLetter1 = words.length > 0 ? words[0][0] : "";
  const firstLetter2 = words.length > 1 ? words[1][0] : "";
  return (
    <UnstyledButton onClick={onClick} disabled={disabeld}>
      <Group>
        <Avatar
          size={40}
          color={disabeld == true ? "gray" : color ? color : "blue"}
        >
          {firstLetter1}
          {firstLetter2}
        </Avatar>
        <div>
          <Text style={{ color: disabeld == true ? "gray" : "" }}>{title}</Text>
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  );
};

export const SettingScreen = ({ showBarCtrl, marginCtrl }) => {
  const intil = useIntl();

  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState("general");

  useEffect(() => {
    document.title = `${intil.formatMessage({
      id: "system_setting_screen",
    })} - SOMECRM`;
  }, []);

  useEffect(() => {
    showBarCtrl(false);
    marginCtrl(false);
  }, []);

  return (
    <div>
      <div style={{ position: "relative" }}>
        <AppShell
          fixed={false}
          styles={{
            main: {
              background:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[8]
                  : theme.colors.gray[0],
            },
          }}
          navbarOffsetBreakpoint="sm"
          asideOffsetBreakpoint="sm"
          navbar={
            <Navbar
              p="md"
              hiddenBreakpoint="sm"
              hidden={!opened}
              width={{ sm: 200, lg: 300 }}
            >
              <UnstyledSettingButton
                onClick={() => setSelected("general")}
                title={"General Settings"}
                description={"Calendar, tasks"}
              />
              <UnstyledSettingButton
                onClick={() => setSelected("default")}
                color={"green"}
                title={"Default Values"}
                description={"Invoice services"}
              />
              <UnstyledSettingButton
                onClick={() => setSelected("rights")}
                disabeld
                title={"User Rights"}
                description={"Can edit, read, send"}
              />
            </Navbar>
          }
          header={
            <Header height={{ base: 50, md: 70 }} p="md">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                  <Burger
                    opened={opened}
                    onClick={() => setOpened((o) => !o)}
                    size="sm"
                    color={theme.colors.gray[6]}
                    mr="xl"
                  />
                </MediaQuery>

                <FormattedMessage id="system_setting_screen" />
              </div>
            </Header>
          }
        >
          <SettingTabs selected={selected} />
        </AppShell>
      </div>
    </div>
  );
};

const SettingTabs = ({ selected }) => {
  const intil = useIntl();
  const [calendar, setCalendar] = useState(null);
  const [adminCalendar, setAdminCalendar] = useState(null);
  const [limitClients, setLimitClients] = useState(null);
  const [limitTasks, setLimitTasks] = useState(null);
  const [sysColor, setSysColor] = useState("#1f2937");
  const [revColor, setRevColor] = useState("#9ca3af");
  const [files, setFiles] = useState(null);

  const [def, setDefault] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDefault({
      ...def,
      [name]: value,
    });
  };

  if (selected == "general") {
    return (
      <div>
        <Title order={2}>General</Title>
        <Grid>
          <Grid.Col xs={12}>
            <Title order={3} style={{ marginTop: 20 }}>
              Visual identity
            </Title>
            <Grid>
              <Grid.Col xs={3}>
                <ColorInput
                  label={intil.formatMessage({ id: "header_color" })}
                  value={sysColor}
                  onChange={setSysColor}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <ColorInput
                  label={intil.formatMessage({ id: "reverse_color" })}
                  value={revColor}
                  onChange={setRevColor}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>

          <Grid.Col xs={12} style={{ marginTop: 20 }}>
            <Title order={3}>Working settings</Title>
            <Grid>
              <Grid.Col xs={3}>
                <Select
                  label={intil.formatMessage({ id: "admin_use_calendar" })}
                  value={adminCalendar}
                  onChange={setAdminCalendar}
                  data={[
                    { value: 1, label: intil.formatMessage({ id: "yes" }) },
                    { value: 0, label: intil.formatMessage({ id: "no" }) },
                  ]}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <Select
                  label={intil.formatMessage({ id: "user_use_calendar" })}
                  value={calendar}
                  onChange={setCalendar}
                  data={[
                    { value: 1, label: intil.formatMessage({ id: "yes" }) },
                    { value: 0, label: intil.formatMessage({ id: "no" }) },
                  ]}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <Select
                  label={intil.formatMessage({ id: "limit_clients" })}
                  value={limitClients}
                  onChange={setLimitClients}
                  data={[
                    { value: 1, label: intil.formatMessage({ id: "yes" }) },
                    { value: 0, label: intil.formatMessage({ id: "no" }) },
                  ]}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <Select
                  label={intil.formatMessage({ id: "limit_tasks" })}
                  value={limitTasks}
                  onChange={setLimitTasks}
                  data={[
                    { value: 1, label: intil.formatMessage({ id: "yes" }) },
                    { value: 0, label: intil.formatMessage({ id: "no" }) },
                  ]}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <Select
                  label={intil.formatMessage({ id: "currency" })}
                  value={limitTasks}
                  onChange={setLimitTasks}
                  data={[
                    {
                      value: "euro",
                      label: intil.formatMessage({ id: "euro" }),
                    },
                    { value: "usd", label: intil.formatMessage({ id: "usd" }) },
                  ]}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <Select
                  disabled={true}
                  label={intil.formatMessage({ id: "show_all_files" })}
                  value={files}
                  onChange={setFiles}
                  data={[
                    { value: 1, label: intil.formatMessage({ id: "yes" }) },
                    { value: 0, label: intil.formatMessage({ id: "no" }) },
                  ]}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </div>
    );
  }

  if (selected == "default") {
    return (
      <div>
        <Title order={2}>Default values</Title>
        <Grid>
          <Grid.Col xs={12}>
            <Title order={3} style={{ marginTop: 20 }}>
              Invoices
            </Title>
            <Grid>
              <Grid.Col xs={6}>
                <TextInput
                  label={intil.formatMessage({ id: "invoice_name_start" })}
                  value={def?.invoice_name}
                  name="invoice_name"
                  onChange={handleInputChange}
                />
              </Grid.Col>
              <Grid.Col xs={6}>
                <TextInput
                  label={intil.formatMessage({ id: "invoice_default_service" })}
                  value={def.invoice_service}
                  name="invoice_service"
                  onChange={handleInputChange}
                />
              </Grid.Col>
              <Grid.Col xs={6}>
                <TextInput
                  label={intil.formatMessage({ id: "invoice_default_price" })}
                  value={def.invoice_price}
                  name="invoice_price"
                  onChange={handleInputChange}
                />
              </Grid.Col>
              <Grid.Col xs={12}></Grid.Col>
              <Grid.Col xs={6}>
                <Textarea
                  label={intil.formatMessage({
                    id: "invoice_email_default_text",
                  })}
                  value={def.invoice_email_text}
                  name="invoice_email_text"
                  onChange={handleInputChange}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </div>
    );
  }
};
