import "./App.css";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import LoginScreen, {
  PassResetScreen,
  ForgotPasswordScreen,
} from "./Screens/User/LoginScreen";
import HomeScreen from "./Screens/User/HomeScreen";
import { MainContainer } from "./Components";
import ProfileScreen from "./Screens/Client/ProfileScreen";
import PartnerScreen from "./Screens/Client/PartnerScreen";
import { useState, useEffect, useContext } from "react";
import {
  getGroup,
  getToken,
  getUserId,
  removeCallToken,
  removeData,
  removeGroup,
  removeToken,
  removeUserId,
} from "./functions/tokens";
import InvoicesPage from "./Screens/Client/InvoicesPage";
import AddInvoiceScreen from "./Screens/Client/AddInvoiceScreen";
import LeadScreen from "./Screens/User/LeadScreen";
import AdminTasks from "./Screens/Admin/AdminTasks";
import AdminUsers from "./Screens/Admin/AdminUsers";
import InvoicePage from "./Screens/Client/InvoicePage";
import PDFscreen from "./Screens/Client/PDFscreen";
import AdminClientScreen from "./Screens/Admin/AdminClients";
import { IntlProvider } from "react-intl";
import { messages } from "./translations";
import { WebPhoneProvider, WebPhoneContext } from "./functions/WebPhoneContext";
import hasAccess from "./functions/access";
import { FileScreen } from "./Screens/Client/FileScreen";
import { AdminScreenRoutes } from "./Screens/Admin/AdminRoutes";
import {
  MarketingScreen,
  MarketingScreenRoutes,
} from "./Screens/Admin/MarketingScreen";
import CalendarScreen from "./Screens/User/CalendarScreen";
import { EventScreen } from "./Screens/Client/EventScreen";
import { SettingScreen } from "./Screens/Admin/SettingScreen";
import { ClientOverviewScreen } from "./Screens/Client/ClientOverviewScreen";
import { TaskOverviewScreen } from "./Screens/User/TaskScreen";

function App() {
  const [isLoggedIn, setLoggedin] = useState(getToken());
  const navigation = useNavigate();
  const [showbar, setShowbar] = useState(true);
  const [margins, setMargins] = useState(true);

  const [locale, setLocale] = useState(
    localStorage.getItem("locale") || navigator.language || "en-GB"
  );
  const { resetPhone } = useContext(WebPhoneContext);

  useEffect(() => {
    setLoggedin(getToken());
  }, [navigation]);

  const Logout = () => {
    removeToken();
    removeGroup();
    removeData();
    removeUserId();
    removeCallToken();
    setLoggedin(false);
    resetPhone();
    window.location.reload();
    return <Navigate to="/login" />;
  };

  const RedirectWrapper = () => (
    <Navigate to={isLoggedIn ? "/main" : "/login"} />
  );

  const LanguageSwitch = () => {
    setLocale(locale == "en-GB" ? "lv-LV" : "en-GB");
    RedirectWrapper();
    return null; //<Navigate to={isLoggedIn ? "/main" : "/login"} />;
  };

  let group = getGroup();
  group = group == null ? 10 : group;

  useEffect(() => {
    const scriptId = "google-maps-api-script";

    // Function to check if the script already exists
    const scriptExists = !!document.getElementById(scriptId);

    if (isLoggedIn && !scriptExists) {
      const script = document.createElement("script");
      script.id = scriptId; // Set the id to the script
      //script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places`;
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCuqPajGGzPY2o6mjSkktTutium8pBdgPg&libraries=places&callback=Function.prototype`;

      script.async = true;
      document.head.appendChild(script); // Append to the head for earlier loading

      return () => {
        // Clean up the script when the component unmounts or on logout
        const existingScript = document.getElementById(scriptId);
        if (existingScript) document.head.removeChild(existingScript);
      };
    }
  }, [isLoggedIn]);

  return (
    <IntlProvider
      locale={locale}
      messages={messages[locale]}
      defaultLocale={"en-GB"}
    >
      <WebPhoneProvider
        useProvider={isLoggedIn}
        key={isLoggedIn && getUserId()}
      >
        <MainContainer
          show={isLoggedIn}
          locale={locale}
          showTop={showbar}
          marginOffset={margins}
        >
          <Routes>
            {/* <Route exct path="/v1/api/:token" element={<ApiRouterScreen />} /> */}
            <Route
              exct
              path="/reset-password/:token"
              element={<PassResetScreen />}
            />
            <Route
              exct
              path="/forgot-password"
              element={<ForgotPasswordScreen />}
            />
            {isLoggedIn && hasAccess(group, "leads") ? (
              <Route exct path="/main" element={<LeadScreen />} />
            ) : (
              <>
                <Route exct path="/main" element={<HomeScreen />} />
                {isLoggedIn && hasAccess(group, "partner") && (
                  <>
                    <Route
                      exct
                      path="/partner/:id"
                      element={<PartnerScreen />}
                    />
                    <Route
                      exct
                      path="/partner/new"
                      element={<PartnerScreen newEntry={true} />}
                    />
                    <Route exct path="/event/:id" element={<EventScreen />} />
                    {/* <Route
                      exct
                      path="/bussiness/:id"
                      element={<BussinessScreen />}
                    />
                    <Route
                      exct
                      path="/bussiness/new"
                      element={<BussinessScreen newEntry={true} />}
                    />
                    <Route
                      exct
                      path="/contract/:id"
                      element={<ContractScreen />}
                    />
                    <Route
                      exct
                      path="/contract/new/:clientid"
                      element={<ContractScreen newEntry={true} />}
                    /> */}
                  </>
                )}
                <Route exct path="/profile" element={<ProfileScreen />} />
                <Route exct path="/files" element={<FileScreen />} />
                <Route exct path="/calendar" element={<CalendarScreen />} />
                <Route
                  exct
                  path="/calendar/:day"
                  element={<CalendarScreen />}
                />
                <Route exct path="/marketing" element={<MarketingScreen />} />
                <Route
                  exct
                  path="/clients"
                  element={<ClientOverviewScreen />}
                />
                <Route exct path="/tasks" element={<TaskOverviewScreen />} />
                {isLoggedIn && hasAccess(group, "invoices") && (
                  <>
                    <Route exct path="/invoices" element={<InvoicesPage />} />
                    {isLoggedIn && hasAccess(group, "invoices", "insert") && (
                      <Route
                        exct
                        path="/invoices/new"
                        element={<AddInvoiceScreen />}
                      />
                    )}
                    <Route
                      exct
                      path="/invoices/:id"
                      element={<InvoicePage />}
                    />
                  </>
                )}
                <Route exct path="/pdfs/:id" element={<PDFscreen />} />
                {isLoggedIn && hasAccess(group, "Admin") && AdminScreenRoutes()}
                ]
                {isLoggedIn && hasAccess(group, "Admin") && (
                  <Route
                    exct
                    path="/admin/system-settings"
                    element={
                      <SettingScreen
                        showBarCtrl={setShowbar}
                        marginCtrl={setMargins}
                      />
                    }
                  />
                )}
                {isLoggedIn &&
                  hasAccess(group, "Admin") &&
                  MarketingScreenRoutes()}
                {isLoggedIn && hasAccess(group, "adminTasks") && (
                  <Route exct path="/admin-tasks" element={<AdminTasks />} />
                )}
                {isLoggedIn && hasAccess(group, "adminUsers") && (
                  <Route exct path="/admin-users" element={<AdminUsers />} />
                )}
                {isLoggedIn && hasAccess(group, "adminClients") && (
                  <Route
                    exct
                    path="/admin-clients"
                    element={<AdminClientScreen />}
                  />
                )}
              </>
            )}
            {isLoggedIn && (
              <>
                <Route exct path="/logout" element={<Logout />} />
                <Route exct path="/profile" element={<ProfileScreen />} />
              </>
            )}
            <Route
              path="/login"
              element={isLoggedIn ? <Navigate to="/main" /> : <LoginScreen />}
            />
            <Route path="/en" element={<LanguageSwitch />} />
            <Route path="/lv" element={<LanguageSwitch />} />
            <Route path="*" element={<RedirectWrapper />} />
          </Routes>
        </MainContainer>
      </WebPhoneProvider>
    </IntlProvider>
  );
}

export default App;
