import { useState, useEffect, useRef } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import {
  checkResetToken,
  handleLogin,
  setNewPassword,
  setPasswordResetToken,
} from "../../functions/api";
import {
  setGroup,
  setToken,
  setUserId,
  setData,
  setCallToken,
} from "../../functions/tokens";
import { useParams, Link } from "react-router-dom";
// import logo from "../../assets/em_logo.png";
import { env } from "../../env";
// import logo from "../../assets/m_logo.png";
import { FormattedMessage, useIntl } from "react-intl";
import background_video from "./sunrise_bg.webm";
import { Lock } from "tabler-icons-react";

const logo = require(`../../assets/${env.LOGO ? env.LOGO : "m_logo.png"}`);

const InputField = ({
  id,
  type,
  value,
  onChange,
  placeholder,
  autoComplete,
  required,
  roundBottom = false,
}) => (
  <div>
    <label htmlFor={id} className="sr-only">
      {placeholder}
    </label>
    <input
      id={id}
      name={id}
      type={type}
      value={value}
      onChange={onChange}
      autoComplete={autoComplete}
      required={required}
      className={`${
        roundBottom ? "rounded-b-md" : "rounded-t-md"
      } relative block w-full appearance-none rounded-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
      placeholder={placeholder}
    />
  </div>
);

const LoginButton = ({ onClick, title }) => (
  <div>
    <button
      onClick={onClick}
      type="submit"
      className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      // className="group relative flex w-full justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    >
      {title}
    </button>
  </div>
);

const BackgroundContainer = ({ children, title }) => (
  <>
    <video
      style={{
        position: "fixed",
        zIndex: -10,
        filter: "brightness(0.8)",
        height: "100%",
        width: "100%",
        objectFit: "cover",
      }}
      autoPlay
      loop
      muted
    >
      <source src={background_video} type="video/mp4" />
    </video>
    <div
      className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
      style={{
        backgroundColor: "#ffffff50", //"rgb(55, 108, 202, 20%)",
        width: "100%",
        position: "fixed",
      }}
    >
      <div className="w-full max-w-md space-y-8">
        <div>
          <img
            style={{ filter: "brightness(0)" }}
            className="mx-auto h-16 w-auto"
            src={logo}
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            {title}
          </h2>
        </div>
        {children}
      </div>
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        bottom: 20,
        width: "100%",
        position: "absolute",
      }}
    >
      <div
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 5,
          paddingBottom: 5,
          backgroundColor: "#00000030",
          color: "white",
          maxWidth: 400,
          borderRadius: 10,
          width: "60%",
          textAlign: "center",
        }}
      >
        Powered by{" "}
        <a style={{ color: "#e9721b" }} href="https://somecrm.com">
          SOMECRM
        </a>
      </div>
    </div>
  </>
);

export default function LoginScreen() {
  const intl = useIntl();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const formRef = useRef(null);

  const navigate = useNavigate();

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleRememberMe = (event) => {
    setRememberMe(event.target.checked);
  };

  useEffect(() => {
    const frmNd = formRef.current;

    const handleAnimationEnd = () => frmNd.classList.remove("shake");

    frmNd && frmNd.addEventListener("animationend", handleAnimationEnd);

    return () =>
      frmNd && frmNd.removeEventListener("animationend", handleAnimationEnd);
  }, [error]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await handleLogin(email, password, rememberMe);
      if (!response.ok) {
        throw new Error(intl.formatMessage({ id: "incorrect_login" }));
      }

      const data = await response.json();
      setToken(data.token);
      setCallToken(data.callToken ? data.callToken : "");
      setGroup(data.group);
      setUserId(data.id);
      setData(data);
      navigate("/main");
    } catch (err) {
      setError(err.message);
      formRef.current.classList.add("shake");
    }
  };

  return (
    <>
      <BackgroundContainer title={<FormattedMessage id="sign_into_account" />}>
        <form className="mt-8 space-y-6" ref={formRef}>
          <input type="hidden" name="remember" defaultValue="true" />
          {error && (
            <div style={{ textAlign: "center", color: "red" }}>{error}</div>
          )}
          <div className="-space-y-px rounded-md shadow-sm">
            <InputField
              id="email-address"
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder={intl.formatMessage({ id: "email" })}
              autoComplete="email"
              required
            />
            <InputField
              id="password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder={intl.formatMessage({ id: "password" })}
              autoComplete="current-password"
              required
              roundBottom
            />
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                checked={rememberMe}
                onChange={handleRememberMe}
              />
              <label
                htmlFor="remember-me"
                className="ml-2 block text-sm text-gray-900"
              >
                <FormattedMessage id="remember_me" />
              </label>
            </div>

            <div className="text-sm">
              <a
                href="/forgot-password"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                <FormattedMessage id="forgot_password" />
              </a>
            </div>
          </div>
          <LoginButton
            title={
              <>
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <Lock color={"lightgrey"} size={20} />
                </span>
                <FormattedMessage id="sign_in" />
              </>
            }
            onClick={handleSubmit}
          />
        </form>
      </BackgroundContainer>
    </>
  );
}

export function ForgotPasswordScreen() {
  const intl = useIntl();
  const [email, setEmail] = useState();
  const [sent, setSent] = useState(false);

  const submitForgotPassword = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email)) {
      setPasswordResetToken(email);
      setSent(true);
    } else {
      window.alert(intl.formatMessage({ id: "invalid_email" }));
    }
  };

  return (
    <>
      <BackgroundContainer
        title={<FormattedMessage id="reset_password_text" />}
      >
        {!sent ? (
          <form className="mt-8 space-y-2">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <InputField
                id="email-address"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={intl.formatMessage({ id: "enter_email" })}
                autoComplete="email"
                required
              />
            </div>
            <div>
              <button
                onClick={submitForgotPassword}
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <FormattedMessage id="reset_password_button" />
              </button>
            </div>
          </form>
        ) : (
          <LoginButton
            onClick={() => (window.location.href = "/login")}
            title={<FormattedMessage id="reset_password_done" />}
          />
        )}
      </BackgroundContainer>
    </>
  );
}

export function PassResetScreen() {
  const intl = useIntl();
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [repeatP, setReapeatP] = useState();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const formRef = useRef(null);

  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password != repeatP) {
      window.alert("Passwords don't match!");
      return;
    }
    try {
      const response = await setNewPassword({ token: token, pass: password });
      if (response.message == "win") {
        navigate("/");
      } else {
        setError(intl.formatMessage({ id: "pwreset_invalid_token" }));
      }
    } catch (error) {
      console.error(error);
      setError(intl.formatMessage({ id: "pwreset_something_wrong" }));
    }
  };

  useEffect(() => {
    checkResetToken({ token: token }).then((data) => {
      setIsLoading(false);
      if (data.error) {
        setError(data.error);
      }
    });
  }, [token]);

  return (
    <>
      {!isLoading && (
        <BackgroundContainer
          title={
            error ? (
              <FormattedMessage id="pwreset_error" />
            ) : (
              <FormattedMessage id="reset_password_text" />
            )
          }
        >
          {error ? (
            <div
              style={{
                backgroundColor: "#ffffff40",
                padding: 20,
                borderRadius: 10,
              }}
            >
              <p className="text-center">
                <FormattedMessage id="link_maybe_expired" />
              </p>
              <div className="mt-6 flex justify-center">
                <Link
                  to="/login"
                  className="text-indigo-600 hover:text-indigo-500"
                >
                  <FormattedMessage id="return_to_login" />
                </Link>
              </div>
            </div>
          ) : (
            <form className="mt-8 space-y-2" ref={formRef}>
              {password && repeatP && password != repeatP && (
                <div style={{ textAlign: "center", color: "red" }}>
                  Passwords don't match
                </div>
              )}
              <div className="rounded-md shadow-sm">
                <InputField
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={intl.formatMessage({ id: "new_password" })}
                  autoComplete="current-password"
                  required
                />
              </div>
              <div className="rounded-md shadow-sm">
                <InputField
                  id="password"
                  type="password"
                  value={repeatP}
                  onChange={(e) => setReapeatP(e.target.value)}
                  placeholder={intl.formatMessage({ id: "repeat_password" })}
                  roundBottom
                  required
                />
              </div>
              <LoginButton
                onClick={handleSubmit}
                title={<FormattedMessage id="reset_password_button" />}
              />
            </form>
          )}
        </BackgroundContainer>
      )}
    </>
  );
}
