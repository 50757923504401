import { Text, Card } from "@mantine/core";

export default function RadioInputs({ data, edit, callBack, selected, containerStyle }) {
  function handleChange(event) {
    callBack(event.target.id);
  }
  return (
    <fieldset style={containerStyle}>
      <legend className="contents text-base text-gray-900">{data.title}</legend>
      <Card withBorder radius="md">
        {data.comment && (
          <p className="text-sm text-gray-500">{data.comment}</p>
        )}
        <div className="mt-1 space-y-4">
          {edit ? (
            data.selects.map((x, index) => {
              return (
                <div key={index} className="flex items-center">
                  <input
                    id={x.id}
                    name={data.name}
                    type="radio"
                    checked={x.id == selected}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor={x.id}
                    className="ml-3 block text-sm font-medium text-gray-700"
                  >
                    {x.label}
                  </label>
                </div>
              );
            })
          ) : (
            <Text>{data.selects.find((o) => o.id == selected)?.label}</Text>
          )}
        </div>
      </Card>
    </fieldset>
  );
}
