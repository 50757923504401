import { Accordion } from "@mantine/core";

export const ToggleBox = ({
  title,
  open,
  content,
  style,
  variant,
  chevronPosition,
  icon,
  panelStyle,
}) => {
  let pS = null;

  if (panelStyle) {
    pS = {
      maxHeight: 400,
      overflow: "hidden",
      overflowY: "auto",
    };
  }

  return (
    <Accordion
      style={
        style
          ? style
          : { padding: 10, paddingTop: 10, flex: 600, minWidth: 350 }
      }
      variant={variant ? variant : "contained"}
      radius="xs"
      chevronPosition={chevronPosition ? chevronPosition : "left"}
      defaultValue={open ? "main" : null}
    >
      <Accordion.Item value={"main"}>
        <Accordion.Control icon={icon ? icon : null}>{title}</Accordion.Control>
        <Accordion.Panel style={pS ? pS : null}>{content}</Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};
