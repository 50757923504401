/* global Phone */
import React, { createContext, useState, useEffect } from "react";
import { getCallToken, getUserId } from "./tokens";

const WebPhoneContext = createContext({
  phone: null,
  resetPhone: () => {},
});

const WebPhoneProvider = ({ children, useProvider }) => {
  const [phone, setPhone] = useState(null);
  const key = getUserId();

  useEffect(() => {
    if (useProvider && key != null && key != false && key != "") {
      if (getCallToken() != "") {
        const script = document.createElement("script");
        script.src =
          "https://www.teltel.io/v2/static/webphone/latest/plugin/webphone.js";
        script.async = true;

        script.onload = () => {
          const phoneInstance = new Phone(getCallToken());
          setPhone(phoneInstance);
        };

        document.body.appendChild(script);
        return () => {
          document.body.removeChild(script);
        };
      }
    }
  }, []);

  const resetPhone = () => {
    if (phone) {
      setPhone(null);
    }
  };

  return useProvider ? (
    <WebPhoneContext.Provider value={{ phone, resetPhone }}>
      {children}
    </WebPhoneContext.Provider>
  ) : (
    children
  );
};

export { WebPhoneContext, WebPhoneProvider };
