import { Pagination } from "@mantine/core";

export const PaginationItem = ({ currentPage, setCurrentPage, totalPages }) => (
  <Pagination
    color="orange"
    boundaries={3}
    size="lg"
    siblings={3}
    value={currentPage}
    onChange={setCurrentPage}
    total={totalPages}
  />
);
