import { useState, useEffect } from "react";
import { MultiSelect } from "@mantine/core";
import { getClients } from "../../functions/api";
import { useIntl } from "react-intl";

const UserMultiSelect = ({ selectedValues, setSelectedValues, preData }) => {
  const intl = useIntl();

  const [query, setQuery] = useState("");
  const [data, setData] = useState([]);

  const [combinedData, setCombinedData] = useState([]);

  function removeNullsFromArray(array) {
    // Ensure the input is an array; return an empty array if not
    if (!Array.isArray(array)) return [];

    // Filter out null values and return the result
    return array?.filter((item) => item !== null);
  }

  useEffect(() => {
    // Combine and filter out nulls only when preData or data changes
    setCombinedData([...removeNullsFromArray(preData), ...data]);
  }, [preData, data]);

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const fetchData = async (searchQuery) => {
    if (!searchQuery) return;

    try {
      const data = await getClients(searchQuery);
      setData(data); // Assuming your API returns an array of { value: string, label: string }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  // Using the custom debounce function
  const debouncedFetchData = debounce((query) => {
    fetchData(query);
  }, 100);

  useEffect(() => {
    debouncedFetchData(query);
  }, [query]);

  return (
    preData && (
      <MultiSelect
        value={selectedValues && removeNullsFromArray(selectedValues)}
        onSearchChange={setQuery}
        onChange={setSelectedValues}
        searchable
        placeholder="Search for users"
        nothingFound="No users found"
        label={intl.formatMessage({ id: "search_client" })}
        xdata={removeNullsFromArray([...preData, data])}
        xxdata={[...data, ...removeNullsFromArray(preData)]}
        data={combinedData}
      />
    )
  );
};

export default UserMultiSelect;
