import { useEffect, useState } from "react";
import { getEvent, getReportFilters } from "../../functions/api";
import { useLocation } from "react-router-dom";
import { Card, Title, Badge, Button } from "@mantine/core";
import { Location, User, BrandZoom } from "tabler-icons-react";
import { useIntl } from "react-intl";
import { getUserId } from "../../functions/tokens";
import EventOverlay from "../../Components/Overlays/EventOverlay";

export const EventScreen = ({ id, data, container = true, editButton }) => {
  const intl = useIntl();

  const location = useLocation();
  let routes = location.pathname.split("/");
  const [event, setEvent] = useState(null);
  const [filters, setFilters] = useState();
  const [edit, setEdit] = useState(editButton);
  const [overlay, setOverlay] = useState(false);

  useEffect(() => {
    getReportFilters().then(async (x) => {
      setFilters(x);
    });
  }, []);

  useEffect(() => {
    if (data) {
      setEvent(data);
      setEdit(editButton);
    } else {
      getEvent({ event_id: id ? id : routes[2] }).then((res) => {
        if (
          getUserId() == res[0].created_by ||
          getUserId() == res[0].main_responsible
        ) {
          setEdit(true);
        } else {
          setEdit(false);
        }
        setEvent(res[0]);
      });
    }
  }, []);

  const getUserByID = (userid) => {
    const mainResponsible = filters?.users?.find((obj) => obj.value == userid);
    return mainResponsible?.label;
  };

  const ContainerElement = ({ children }) => {
    if (container) {
      return (
        <Card withBorder radius="md" style={{ margin: 10 }}>
          {children}
        </Card>
      );
    } else {
      return <>{children}</>;
    }
  };

  return (
    <>
      {overlay && event && (
        <EventOverlay
          newTabBtn={false}
          open={overlay}
          setOpen={setOverlay}
          data={event && event}
        />
      )}
      <ContainerElement>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Title
            order={2}
            style={{
              marginBottom: 20,
              flex: 2,
              textDecoration:
                event?.event_status == "cancel" ? "line-through" : "none",
              color: event?.event_status == "cancel" ? "red" : "inherit",
            }}
          >
            {event?.title ? event?.title : event?.event_title}
          </Title>
          <div style={{ flex: 1, textAlign: "right" }}>
            {!edit && event?.created_by && (
              <small title={intl.formatMessage({ id: "created_by" })}>
                {intl.formatMessage({ id: "created_by" })}{" "}
                {getUserByID(event?.created_by)}
              </small>
            )}
            {event?.event_status == "cancel" && (
              <Badge style={{ float: "right" }} size="xl" color={"red"}>
                {intl.formatMessage({ id: "event_cancel" })}
              </Badge>
            )}
            {edit && event?.event_status != "cancel" && (
              <div>
                <Button
                  onClick={() => setOverlay(true)}
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    backgroundColor: "dodgerblue",
                    width: 100,
                  }}
                >
                  {intl.formatMessage({ id: "edit" })}
                </Button>
              </div>
            )}
            <Badge
              style={{ float: "right" }}
              size="xl"
              color={
                event?.event_type == "meeting"
                  ? "green"
                  : event?.event_type == "event"
                  ? "red"
                  : null
              }
            >
              {event?.event_type}
            </Badge>
          </div>
        </div>
        <br />
        <div>
          {event?.address && (
            <div style={{ marginTop: 5, marginBottom: 5, display: "flex" }}>
              <Location />
              <a
                style={{
                  color: "dodgerblue",
                  textDecoration: "underline",
                  marginLeft: 5,
                }}
                href={`https://www.google.com/maps/search/?api=1&query=${event?.coords?.y},${event?.coords?.x}`}
                target="_blank"
                title={intl.formatMessage({ id: "open_in_maps" })}
              >
                {" "}
                {event?.address}
              </a>
            </div>
          )}

          {event?.event_link && (
            <div
              style={{ marginTop: 5, marginBottom: 5, display: "flex" }}
              title={intl.formatMessage({ id: "event_link" })}
            >
              <BrandZoom />
              <a
                style={{
                  color: "dodgerblue",
                  textDecoration: "underline",
                  marginLeft: 5,
                }}
                href={`${event?.event_link}`}
                target="_blank"
                title={intl.formatMessage({ id: "open_new_tab" })}
              >
                {" "}
                {event?.event_link}
              </a>
            </div>
          )}

          {event?.main_responsible && (
            <div
              style={{ marginTop: 5, marginBottom: 5, display: "flex" }}
              title={intl.formatMessage({ id: "main_responsible" })}
            >
              <User />
              <span style={{ marginLeft: 5 }}>
                {getUserByID(event?.main_responsible)}
              </span>
            </div>
          )}
          <div style={{ maxWidth: 350 }}>
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <span>{new Date(event?.event_start_time).toLocaleString()}</span>
              {event?.event_end_time && " - "}
              <span>
                {event?.event_end_time
                  ? `${new Date(event?.event_end_time).toLocaleString()}`
                  : ""}
              </span>
            </span>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <Card withBorder>{event?.event_description}</Card>
        </div>
        {event?.involved_users?.some((item) => item?.user_id != null) && (
          <div style={{ marginTop: 20 }}>
            <div>
              <Title order={3}>
                {intl.formatMessage({ id: "involved_users" })}
              </Title>
            </div>
            <div>
              {event?.involved_users?.map((user) => (
                <div>{getUserByID(user)}</div>
              ))}
            </div>
          </div>
        )}
        {event?.clients?.some((item) => item.client_id != null) && (
          <div style={{ marginTop: 20 }}>
            <div>
              <Title order={3}>
                {intl.formatMessage({ id: "involved_clients" })}
              </Title>
            </div>
            <div>
              {event?.clients?.map(
                (client) =>
                  client.client_id != null && (
                    <div>
                      <a
                        style={{
                          textDecoration: "underline",
                          color: "dodgerblue",
                        }}
                        href={`/partner/${client.client_id}`}
                      >
                        {client.c_name} {client.c_surname} ({client.c_email}{" "}
                        {client.c_email && client.c_phone && " | "}{" "}
                        {client.c_phone})
                      </a>
                    </div>
                  )
              )}
            </div>
          </div>
        )}
      </ContainerElement>
    </>
  );
};
