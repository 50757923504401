import React, { useEffect, useState } from "react";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { useDisclosure } from "@mantine/hooks";

function findFirstNonNullCoords(tasks) {
  return Object.keys(tasks).some((taskKey) => {
    const task = tasks[taskKey];
    return task.coords !== null;
  });
}

const TaskMap = ({ tasks, callBack }) => {
  const [over, setOver] = useState();
  const [show, setShow] = useState();

  const checkGoogleState = () => {
    if (
      "google" in window &&
      typeof window.google === "object" &&
      typeof window.google.maps === "object"
    ) {
      setShow(findFirstNonNullCoords(tasks));
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkGoogleState();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [tasks]);

  const mapContainerStyle = {
    width: "100%",
    height: 200,
    position: "relative",
    marginLeft: 5,
    marginRight: 5,
  };

  const [opened, { toggle, close }] = useDisclosure(false);

  const centerTask = tasks.find((task) => task.coords);
  const center = {
    lat: centerTask?.coords?.y || 0,
    lng: centerTask?.coords?.x || 0,
  };

  const now = new Date();

  return (
    <>
      {show && (
        <>
          <div style={{ marginTop: 15 }}></div>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={10}
            options={{
              mapTypeControl: false,
              fullscreenControl: false,
              streetViewControl: false,
            }}
          >
            {tasks.map((task) => (
              <MarkerF
                icon={{
                  url:
                    new Date(task.deadline) > now
                      ? "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
                      : "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                }}
                onMouseOut={(e) => {
                  setOver(null);
                  // op.current.toggle(e);
                }}
                onMouseOver={(e) => {
                  setOver(task);
                  // toggle();
                }}
                onClick={() => callBack(task)}
                key={task.taskid}
                color="green"
                position={{
                  lat: Number(task.coords?.y),
                  lng: Number(task.coords?.x),
                }}
              />
            ))}
          </GoogleMap>
        </>
      )}
    </>
  );
};

export default TaskMap;
