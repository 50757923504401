import { useEffect, useState } from "react";
// import { ActionBar } from "../ActionBar";
import { ActionBar } from "../";
import SideOverlay from "./SideOverlay";
import TaskOverlay from "./TaskOverlay";
import { ParnterScreenFields } from "../../Screens/Client/PartnerScreen";
import { SourceBadge } from "../Inputs/EditableMulti";
import { CallButton } from "../CallButton";
import { TaskItem } from "../TaskLine";
import { additionalDisplay } from "../../Screens/User/HomeScreen";

export const handleAction = (i, type, taskOverlay) => {
  const displayMore = false;
  i =
    type == "task"
      ? {
          ...i,
          component: (
            <>
              <ParnterScreenFields data={i} />
              {i.sources &&
                i.sources.map((cs) => cs && <SourceBadge cs={cs} />)}
              <div style={{ marginTop: 20 }}>
                <CallButton client={i} />
              </div>
              <div
                style={{ marginTop: 20 }}
                className="bg-white rounded-md shadow-lg"
              >
                {i && (i.task_name || i.task_description) && (
                  <TaskItem
                    onClick={() => taskOverlay(i)}
                    task={i}
                    displayData={additionalDisplay(displayMore)}
                  />
                )}
              </div>
            </>
          ),
          route: i.clientid ? "/partner/" + i.clientid : null,
        }
      : i.type == "task"
      ? taskOverlay(i)
      : i;
  return i;
};

const OverlayCombined = ({
  sidemodal,
  setSidemodal,
  modalData,
  setModaldata,
  overlay,
  setOverlay,
  ovData,
  setOvdata,
  displayActions = true,
}) => {
  const taskOverlay = (data) => {
    setOvdata(data);
    setOverlay(true);
  };

  const callHandleAction = (i, type) => {
    handleAction(i, type, taskOverlay);
    if (i.type != "task") {
      setModaldata(i);
      setSidemodal(true);
    }
  };

  return (
    <>
      {overlay && (
        <TaskOverlay
          key={displayActions ? "dato" : "nato"}
          open={overlay}
          setOpen={setOverlay}
          data={ovData}
          callBack={setSidemodal}
        />
      )}
      {displayActions && <ActionBar action={callHandleAction} />}
      {sidemodal && (
        <SideOverlay
          key={displayActions ? "daso" : "naso"}
          open={sidemodal}
          setOpen={setSidemodal}
          data={modalData}
        />
      )}
    </>
  );
};

export default OverlayCombined;
