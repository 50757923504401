import React, { useState, useEffect, useRef } from "react";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

const AutoSelect = ({
  title,
  getData,
  callBack,
  placeholder,
  customReset,
  customStyle,
  preselected,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (query.length > 2) {
      getData(query).then((data) => setOptions(data));
      // console.log(options, query.length > 2, options.length == 0);
    } else {
      setOptions([]);
    }
  }, [query]);

  useEffect(() => {
    if (preselected) {
      setSelectedOption(preselected);
      setQuery("");
      setOptions([]);
    }
  }, []);

  const inputRef = useRef(null);

  const handleReset = () => {
    setSelectedOption(null);
    setQuery("");
    callBack(null);
    inputRef?.current?.focus();
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    callBack(option);
    setQuery("");
    setOptions([]);
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  return (
    <FormControl>
      <FormLabel fontSize="lg">{title}</FormLabel>
      {selectedOption ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={
              customStyle
                ? customStyle
                : {
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "15px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }
            }
          >
            {selectedOption.name} {selectedOption.surname}
            {!customReset && (
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                  marginLeft: "5px",
                  fontWeight: 800,
                  color: "red",
                  flex: "end",
                }}
                onClick={handleReset}
              >
                X
              </button>
            )}
          </div>
          {customReset && <span onClick={handleReset}>{customReset}</span>}
        </div>
      ) : (
        <div>
          <input
            type="text"
            value={query}
            onChange={handleInputChange}
            placeholder={placeholder}
            style={{ width: "100%" }}
            ref={inputRef}
          />
          <ul>
            {options &&
              options.length > 0 &&
              options.map((option) => (
                <li
                  style={{
                    padding: 5,
                    paddingRight: 10,
                    paddingLeft: 10,
                    backgroundColor: "#e8e8e860",
                    border: "solid 0.5px grey",
                  }}
                  key={option.id}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.name} {option.surname}
                </li>
              ))}
            {options && options.error && (
              <li
                style={{
                  padding: 5,
                  paddingRight: 10,
                  paddingLeft: 10,
                  backgroundColor: "#e8e8e860",
                  border: "solid 0.5px grey",
                }}
              >
                <FormattedMessage id="not_found" />
              </li>
            )}
          </ul>
        </div>
      )}
    </FormControl>
  );
};

export default AutoSelect;
